import React from 'react';
import { Link } from 'react-router-dom';
const replacements = {
    '': '™',
    '': '"',
    '': '"',
    '®': '®',
    '©': '©',
    '': '—',
    '': '–',
    '': '•',
    '': '\'',
    '': '\'',
    '': '…',
    '': '¨',
    '': '‰',
    '': '‹',
    '': '›',
    '': '«',
    '': '»',
}
const specialty_mappings = {
    "Anesthesiology": "AN",
    "Cardiovascular": "CV",
    "Clinical Chemistry": "CH",
    "Dental": "DE",
    "Ear, Nose, Throat": "EN",
    "Gastroenterology, Urology": "GU",
    "General Hospital": "HO",
    "Hematology": "HE",
    "Immunology": "IM",
    "Medical Genetics": "MG",
    "Microbiology": "MI",
    "Neurology": "NE",
    "Obstetrics/Gynecology": "OB",
    "Ophthalmic": "OP",
    "Orthopedic": "OR",
    "Pathology": "PA",
    "Physical Medicine": "PM",
    "Radiology": "RA",
    "General, Plastic Surgery": "SU",
    "Clinical Toxicology": "TX",
    "Unknown": "UN"
}
const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {
    return (

        <div className='table-responsive px-5 mb-6'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        <th style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation(); // Prevent event from propagating to parent elements
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('k_number.keyword');
                                }
                            }}>
                            K Number
                            <span className={`sorting-icon ${sortConfig.key === 'k_number.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'k_number.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '30%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('device_name.keyword');
                                }
                            }}
                        >
                            Device Name
                            <span className={`sorting-icon ${sortConfig.key === 'device_name.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'device_name.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('applicant.keyword');
                                }
                            }}
                        >
                            Company
                            <span className={`sorting-icon ${sortConfig.key === 'applicant.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'applicant.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-center ${sortConfig.key !== 'openfda.device_class.keyword' ? 'pe-2' : ''}`} style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.device_class.keyword');
                                }
                            }}
                        >
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.device_class.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.device_class.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.medical_specialty_description.keyword');
                                }
                            }}
                        >
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.medical_specialty_description.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.medical_specialty_description.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-center ${sortConfig.key !== 'product_code.keyword' ? 'pe-2' : ''}`} style={{ whiteSpace: 'nowrap', width: '15%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('product_code.keyword');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        


                        <th className={`text-end ${sortConfig.key !== 'decision_date' ? 'pe-3' : ''}`} style={{ whiteSpace: 'nowrap', width: '15%', textAlign: 'right', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('decision_date');
                                }
                            }}
                        >
                            Decision Date
                            <span className={`sorting-icon ${sortConfig.key === 'decision_date' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'decision_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '9%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={12} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={12} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((device, index) =>
                            (<tr className='align-items-center bg-hover-light-primary' onClick={() => {
                                window.open(`/device-intelligence/device/${device._source.k_number}`, '_blank');
                                
                            }}>
                                <td style={{ verticalAlign: 'middle' }} className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                    {device._source.k_number}
                                </td>
                                <td>
                                    <a href='/device-intelligence/device' className='text-dark text-wrap fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>

                                        {Object.keys(replacements).reduce((str, key) => {
                                            return str.replace(new RegExp(key, 'g'), replacements[key]);
                                        }, device._source.device_name)}

                                    </a>
                                </td>
                                <td>
                                    <a href='/device-intelligence/device' className='text-dark text-wrap fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                        {device._source.applicant}
                                    </a>
                                </td>
                                <td>
                                    <a href='/device-intelligence/device' className='text-dark fw-semibold d-block fs-6 text-center' onClick={(e) => e.preventDefault()}>
                                        {device._source.openfda.device_class}
                                    </a>
                                </td>
                                <td>
                                    <a href='/device-intelligence/device' className='text-dark text-wrap fw-semibold d-block fs-6 text-center' onClick={(e) => e.preventDefault()}>
                                        {specialty_mappings[device._source.openfda.medical_specialty_description]}
                                    </a>
                                </td>
                                <td className={`text-center text-dark text-wrap fw-semibold ps-3 fs-6 ${sortConfig.key === 'product_code' ? 'pe-4' : ''}`} style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                    <Link to={`/device-intelligence/classification/${device._source.product_code}`} style={{ color: 'primary' }} className='align-items-center' target="_blank" >{device._source.product_code}</Link>
                                </td>
                                


                                <td className='text-end'>
                                    <a href='/device-intelligence/device' className={`text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'decision_date' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                        {new Date(device._source.decision_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                    </a>
                                </td>
                                <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                    <Link
                                        to={`/device-intelligence/device/${device._source.k_number}`}
                                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                                        target='_blank'
                                    >
                                        Details
                                    </Link>
                                </td>
                            </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;
import { SidebarMenuMain } from './SidebarMenuMain'

const SidebarMenu = () => {
  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper my-5'
        style={{
          height: 'calc(100vh - 100px)',  // Dynamic height calculation
          overflowY: 'auto',              // Always show scrollbar when needed
          overflowX: 'hidden',            // Prevent horizontal scroll
          paddingRight: '5px',            // Space for scrollbar
        }}
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='true'
          style={{ overflow: 'visible' }}
        >
          <SidebarMenuMain />
        </div>
      </div>
    </div>
  )
}

export { SidebarMenu }
import React, { useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Modal, Form, Button } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import execBriefDoc from './exec-brief.md'
import productPulseDoc from './product-pulse.md'
import marketWatcherDoc from './market-watcher.md'
import deviceIQDoc from './device-iq.md'
import { jsPDF } from 'jspdf'  

interface AgentRequest {
    id: number
    description: string
    contactInfo: string
    timestamp: Date
    status: 'pending' | 'reviewed' | 'contacted'
}  

const ExperimentalBadge = () => (
  <span
    className="badge badge-light-info ms-3 mb-1"
    style={{
      fontSize: "0.85rem",
      padding: "0.4rem 0.8rem",
      borderRadius: "12px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      fontWeight: "500",
      textTransform: "uppercase",
    }}
  >
    Experimental Feature
  </span>
)

const ExperimentalTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id="experimental-tooltip">This feature is in evolution with input from users</Tooltip>}
  >
    {({ ref, ...triggerHandler }) => (
      <div {...triggerHandler} className="me-2">
        <i ref={ref} className="bi bi-question-circle-fill fs-3 fw-bold text-info"></i>
      </div>
    )}
  </OverlayTrigger>
)

const LeximAgents = () => {
    const [activeFilter, setActiveFilter] = useState<string | null>(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [schedules, setSchedules] = useState<{[key: string]: string}>({});
    const [showDocModal, setShowDocModal] = useState(false)
    const [markdownContent, setMarkdownContent] = useState('')
    const [title, setTitle] = useState('')

    const handleScheduleSelect = (agentTitle: string, schedule: string) => {
        setSchedules(prev => {
          const newSchedules = { ...prev };
          if (schedule === '') {
            delete newSchedules[agentTitle];
          } else {
            newSchedules[agentTitle] = schedule;
          }
          return newSchedules;
        });
        
        localStorage.setItem('agentSchedules', JSON.stringify(schedules));
    };

    const handleDocClick = async (e: React.MouseEvent, agentTitle: string) => {
        e.preventDefault()  // Prevent navigation
        if (agentTitle === 'FOIA Fetcher') {
          setShowFOIAModal(true)
          return;
        } 
        let docPath = '';
        let title = '';

        switch(agentTitle) {
            case 'Exec Brief':
                docPath = execBriefDoc;
                title = 'Executive Brief';
                break;
            case 'Product Pulse':
                docPath = productPulseDoc;
                title = 'Product Pulse';
                break;
            case 'Market Watcher':
                docPath = marketWatcherDoc;
                title = 'Market Watcher';
                break;
            case 'Device IQ':
                docPath = deviceIQDoc;
                title = 'Device IQ';
                break;
            default:
                return;
        }

        try {
            const response = await fetch(docPath);
            if (!response.ok) throw new Error('Failed to fetch document');

            const content = await response.text();
            setMarkdownContent(content);
        } catch (error) {
            console.error('Error loading documentation:', error);
            setMarkdownContent('Error loading documentation.');
        }

        setTitle(title);
        setShowDocModal(true);

  }
            

  const handleDownloadPDF = () => {
    const doc = new jsPDF()
    doc.html(markdownContent)
    doc.save('exec-brief-documentation.pdf')
  }

  const buttonStyle = {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as const,
    padding: '20px',
    border: '1px solid #eee',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    backgroundColor: 'white',
    color: '#4d4d4d',
    textDecoration: 'none',
    cursor: 'pointer',
  }
  const hoverStyle = {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
  }

  const agents = [
    {
      title: 'Exec Brief',
      icon: 'notepad',
      description: 'Regulatory developments and key insights summarized into concise executive-style reports for leadership teams',
      path: '/lexim-agents',
      type: 'Regulatory'
    },
    {
      title: 'Product Pulse',
      icon: 'chart-line',
      description: 'Tailored updates for product teams, highlighting relevant FDA changes, approvals, or compliance shifts',
      path: '/lexim-agents',
      type: 'Product'
    },
    {
      title: 'FOIA Fetcher',
      icon: 'abstract-26',
      description: 'Automate Freedom of Information Act (FOIA) requests and track responses for documents tied to devices, companies, or classifications',
      path: '/lexim-agents',
      type: 'Regulatory'
    },
    {
      title: 'RegAlert',
      icon: 'notification-bing',
      description: 'Monitor selected topics and schedule real-time alerts when relevant guidance, rules, or commentary are published',
      path: '/lexim-agents',
      type: 'Regulatory'
    },
    {
      title: 'Market Watcher',
      icon: 'eye',
      description: 'Track competitor regulatory activity - approvals, 510(k)s, PMAs, recalls',
      path: '/lexim-agents',
      type: 'Product'
    },
    {
      title: 'Device IQ',
      icon: 'graph-up',
      description: 'Analyze individual medical devices by synthesizing regulatory history, classification data and adverse events',
      path: '/lexim-agents',
      type: 'Quality'
    }
  ]
  const filteredAgents = agents.filter(agent => {
    if (activeFilter && agent.type !== activeFilter) {
      return false
    }
    
    if (searchTerm) {
      return agent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
             agent.description.toLowerCase().includes(searchTerm.toLowerCase())
    }

    return true
  })
  const [showRequestModal, setShowRequestModal] = useState(false)
  // const [showViewRequestsModal, setShowViewRequestsModal] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [requestForm, setRequestForm] = useState<AgentRequest>({
    id: Date.now(),
    description: '',
    contactInfo: '',
    timestamp: new Date(),
    status: 'pending'
  })
  const [showFOIAModal, setShowFOIAModal] = useState(false);
  const [foiaForm, setFoiaForm] = useState({
    agencyName: '',
    requestDescription: '',
    timeframeStart: '',
    timeframeEnd: '',
    fullName: '',
    email: '',
    phone: '',
    mailingAddress: '',
    preferredFormat: 'digital',
    feeAgreement: false,
    expeditedProcessing: false,
    expeditedJustification: ''
  });

  const handleFOIASubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('FOIA Request:', foiaForm);
    // Add your submission logic here
    setShowFOIAModal(false);
    // Show success toast
    setShowSuccessToast(true);
  };


  
  const getStoredRequests = (): AgentRequest[] => {
    const stored = localStorage.getItem('agentRequests')
    return stored ? JSON.parse(stored) : []
  }

  const handleRequestSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    
    const existingRequests = getStoredRequests()
    const newRequest = {
      ...requestForm,
      timestamp: new Date(),
      id: Date.now()
    }
    
    localStorage.setItem('agentRequests', JSON.stringify([...existingRequests, newRequest]))
    
    setRequestForm({
      id: Date.now(),
      description: '',
      contactInfo: '',
      timestamp: new Date(),
      status: 'pending'
    })
    setShowRequestModal(false)
    setShowSuccessToast(true)
  }
  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className="container px-8">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex flex-column py-0">
              <div className="d-flex flex-column-auto mb-2 mt-2">
                <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                  <div className="d-flex align-items-center">
                    <KTIcon iconName='robot' iconType="duotone" className='fw-bold fs-1 me-3 text-primary' />
                    <span>Lexim Agents</span>
                  </div>
                </h1>
                <div className="d-flex align-items-center">
                  <div className="px-2">
                    <ExperimentalBadge />
                  </div>
                  <div className="px-2">
                    <ExperimentalTooltip />
                  </div>
                </div>
              </div>
            </div>
            <button 
              className="btn btn-primary px-6"
              style={{ height: 'fit-content', marginRight: '12px' }}
              onClick={() => setShowRequestModal(true)}
            >
              <KTIcon iconName='plus-square' className='fs-2 me-2' />
              Request an Agent
            </button>
          </div>
        </div>
        <Modal
          show={showRequestModal}
          onHide={() => setShowRequestModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="d-flex align-items-center">
                <KTIcon iconName="document" className="fs-1 text-primary me-4" />
                Request a New Agent
              </div>
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={handleRequestSubmit}>
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <h4 className="mb-4 border-bottom pb-2">Request Details</h4>

              <Form.Group className="mb-4">
                <Form.Label>Describe the functionality you're looking for</Form.Label>
                <Form.Text className="text-muted fs-7 d-block mb-2">
                  Please provide details about the specific regulatory, quality, or product-related tasks you'd like the agent to handle.
                </Form.Text>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Example: I need an agent that can monitor specific FDA guidance documents and alert me when updates are published for my product category..."
                  value={requestForm.description}
                  onChange={(e) =>
                    setRequestForm((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  required
                  className="fs-6"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Contact Information (Optional)</Form.Label>
                <Form.Text className="text-muted fs-7 d-block mb-2">
                  If you'd like us to follow up with questions or updates, please provide your preferred contact method.
                </Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Email address or phone number"
                  value={requestForm.contactInfo}
                  onChange={(e) =>
                    setRequestForm((prev) => ({
                      ...prev,
                      contactInfo: e.target.value,
                    }))
                  }
                  className="fs-6"
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="light" onClick={() => setShowRequestModal(false)} className="btn-lg">
                Cancel
              </Button>
              <Button variant="primary" type="submit" className="btn-lg">
                Submit Request
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>


        <Modal
          show={showSuccessToast}
          onHide={() => setShowSuccessToast(false)}
          centered
          size="sm"
          className="bg-transparent"
        >
          <div 
            className="bg-white rounded shadow-sm p-8 d-flex flex-column align-items-center text-center"
            style={{
              border: '1px solid #E4E6EF',
              boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)'
            }}
          >
            <div className="symbol symbol-100px mb-5">
              <div className="symbol-label bg-light-success rounded-circle">
                <KTIcon iconName='check-circle' className='fs-1 text-success' />
              </div>
            </div>
            <div className="mb-8">
              <h3 className="fs-2 fw-bold mb-1">Request Submitted!</h3>
              <div className="text-gray-600 fw-semibold fs-6">
                Your agent request has been successfully submitted.
                <br />We'll review it shortly.
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary px-6"
              onClick={() => setShowSuccessToast(false)}
            >
              Got it, thanks!
            </button>
          </div>
        </Modal>
        <Modal
  show={showFOIAModal}
  onHide={() => setShowFOIAModal(false)}
  size="lg"
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>
      <div className="d-flex align-items-center">
        <KTIcon iconName="document" className="fs-1 text-primary me-4" />
        FOIA Fetcher
      </div>
    </Modal.Title>
  </Modal.Header>
  <Form onSubmit={handleFOIASubmit}>
    <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
      <h4 className="mb-4 mt-3 border-bottom pb-2">Submission Information</h4>

      <Form.Group className="mb-4">
        <Form.Label>Agency Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the government agency name"
          value={foiaForm.agencyName}
          onChange={(e) => setFoiaForm(prev => ({ ...prev, agencyName: e.target.value }))}
          required
          className="fs-6"
        />
        <Form.Text className="text-muted fs-7">
          The government agency to which the FOIA request is being submitted.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Request Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder="Provide a clear description of the records being requested"
          value={foiaForm.requestDescription}
          onChange={(e) => setFoiaForm(prev => ({ ...prev, requestDescription: e.target.value }))}
          required
          className="fs-6"
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Timeframe of Records (if applicable)</Form.Label>
        <div className="d-flex gap-3">
          <div className="flex-grow-1">
            <Form.Label className="small">Start Date</Form.Label>
            <Form.Control
              type="date"
              value={foiaForm.timeframeStart}
              onChange={(e) => setFoiaForm(prev => ({ ...prev, timeframeStart: e.target.value }))}
              className="fs-6"
            />
          </div>
          <div className="flex-grow-1">
            <Form.Label className="small">End Date</Form.Label>
            <Form.Control
              type="date"
              value={foiaForm.timeframeEnd}
              onChange={(e) => setFoiaForm(prev => ({ ...prev, timeframeEnd: e.target.value }))}
              className="fs-6"
            />
          </div>
        </div>
      </Form.Group>

      <h4 className="mb-4 mt-5 border-bottom pb-2">Requester Information</h4>

      <Form.Group className="mb-4">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your full name"
          value={foiaForm.fullName}
          onChange={(e) => setFoiaForm(prev => ({ ...prev, fullName: e.target.value }))}
          required
          className="fs-6"
        />
      </Form.Group>

      <div className="row mb-4">
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={foiaForm.email}
              onChange={(e) => setFoiaForm(prev => ({ ...prev, email: e.target.value }))}
              required
              className="fs-6"
            />
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={foiaForm.phone}
              onChange={(e) => setFoiaForm(prev => ({ ...prev, phone: e.target.value }))}
              className="fs-6"
            />
          </Form.Group>
        </div>
      </div>

      <Form.Group className="mb-4">
      <Form.Label>Preferred Format</Form.Label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="preferredFormatSwitch"
          checked={foiaForm.preferredFormat === "physical"}
          onChange={() =>
            setFoiaForm(prev => ({
              ...prev,
              preferredFormat: prev.preferredFormat === "digital" ? "physical" : "digital",
            }))
          }
        />
        <label className="form-check-label ms-2" htmlFor="preferredFormatSwitch">
          {foiaForm.preferredFormat === "digital" ? "Digital" : "Physical"}
        </label>
      </div>
    </Form.Group>

      {foiaForm.preferredFormat === "physical" && (
        <Form.Group className="mb-4">
          <Form.Label>Mailing Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter your mailing address"
            value={foiaForm.mailingAddress}
            onChange={(e) => setFoiaForm(prev => ({ ...prev, mailingAddress: e.target.value }))}
            required
            className="fs-6"
          />
          <Form.Text className="text-muted fs-7">
            Required for physical document delivery.
          </Form.Text>
        </Form.Group>
      )}

      <Form.Group className="mb-4">
        <Form.Check
          type="checkbox"
          label="I agree to pay applicable fees for this request"
          checked={foiaForm.feeAgreement}
          onChange={(e) => setFoiaForm(prev => ({ ...prev, feeAgreement: e.target.checked }))}
          id="feeAgreement"
          className="fs-6"
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Check
          type="checkbox"
          label="Request expedited processing"
          checked={foiaForm.expeditedProcessing}
          onChange={(e) => setFoiaForm(prev => ({ ...prev, expeditedProcessing: e.target.checked }))}
          id="expeditedProcessing"
          className="fs-6"
        />
      </Form.Group>

      {foiaForm.expeditedProcessing && (
        <Form.Group className="mb-4">
          <Form.Label>Justification for Expedited Processing</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Provide justification for expedited processing"
            value={foiaForm.expeditedJustification}
            onChange={(e) => setFoiaForm(prev => ({ ...prev, expeditedJustification: e.target.value }))}
            required
            className="fs-6"
          />
        </Form.Group>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="light" onClick={() => setShowFOIAModal(false)} className="btn-lg">
        Cancel
      </Button>
      <Button variant="primary" type="submit" className="btn-lg">
        Submit Request
      </Button>
    </Modal.Footer>
  </Form>
</Modal>

        <div className="container px-8">
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center position-relative">
              <KTIcon iconName='magnifier' className='fs-3 text-gray-500 position-absolute ms-4' />
              <input
                type="text"
                className="form-control form-control-solid ps-12"
                placeholder="Search Agents..."
                style={{ width: '300px' }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
            <div className="d-flex gap-3">
                {/* All Button */}
                <button 
                    className={`btn ${activeFilter === null ? 'btn-primary' : 'btn-light-primary'}`}
                    style={{ 
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => setActiveFilter(null)}
                >
                    <KTIcon iconName='element-11' className='fs-3 me-2' />
                    All
                </button>
                <button 
                    className={`btn ${activeFilter === 'Quality' ? 'btn-primary' : 'btn-light-primary'}`}
                    style={{ 
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => setActiveFilter('Quality')}  // Removed toggle logic
                >
                    <KTIcon iconName='check-circle' className='fs-3 me-2' />
                    Quality
                </button>
                <button 
                    className={`btn ${activeFilter === 'Regulatory' ? 'btn-primary' : 'btn-light-primary'}`}
                    style={{ 
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => setActiveFilter('Regulatory')}  // Removed toggle logic
                >
                    <KTIcon iconName='shield-tick' className='fs-3 me-2' />
                    Regulatory
                </button>
                <button 
                    className={`btn ${activeFilter === 'Product' ? 'btn-primary' : 'btn-light-primary'}`}
                    style={{ 
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => setActiveFilter('Product')}  // Removed toggle logic
                >
                    <KTIcon iconName='package' className='fs-3 me-2' />
                    Product
                </button>
            </div>
          </div>
        </div>
        <div className="container px-8 p-3 mt-5">
          <div className="row g-8">
            {filteredAgents.map((agent, index) => (
              <div key={index} className="col-md-4">
                <Link 
                    to={agent.path}
                    className="card h-100 position-relative"
                    style={buttonStyle}
                    onClick={(e) => handleDocClick(e, agent.title)}
                    onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'none'
                        e.currentTarget.style.boxShadow = 'none'
                    }}
                    >
                  <span 
                    className={`badge position-absolute`}
                    style={{
                      top: '10px',
                      right: '10px',
                      fontSize: '0.75rem',
                      padding: '0.3rem 0.7rem',
                      borderRadius: '8px',
                      backgroundColor: agent.type === 'Regulatory' 
                        ? '#fff8dd' 
                        : agent.type === 'Quality'
                        ? '#e8f0f8'
                        : '#f8f5ff',
                      color: agent.type === 'Regulatory'
                        ? '#ffc700'
                        : agent.type === 'Quality'
                        ? '#0095e8'
                        : '#7239ea'
                    }}
                  >
                    {agent.type}
                  </span>
                  {(agent.title === 'Exec Brief' || 
                    agent.title === 'Market Watcher' || 
                    agent.title === 'Product Pulse' || 
                    agent.title === 'RegAlert') && (
                    <Dropdown 
                        className="position-absolute" 
                        style={{ 
                        top: '10px', 
                        left: '10px',
                        transform: 'scale(0.95)'
                        }}
                    >
                        <Dropdown.Toggle 
                        variant={schedules[agent.title] ? "primary" : "light-primary"}  // Use agent.title instead of index
                        size="sm"
                        className="btn-icon p-2"
                        id={`schedule-dropdown-${agent.title}`}
                        >
                        <KTIcon 
                            iconName='timer' 
                            className={`fs-2 ${schedules[agent.title] ? 'text-white' : ''}`}  // Use agent.title
                        />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        <Dropdown.Header>Schedule Report</Dropdown.Header>
                        <Dropdown.Item 
                            onClick={(e) => {
                            e.preventDefault()
                            handleScheduleSelect(agent.title, 'weekly')  // Pass agent.title instead of index
                            }}
                            active={schedules[agent.title] === 'weekly'} 
                            className={schedules[agent.title] === 'weekly' ? 'bg-light-primary' : ''}
                        >
                            <KTIcon iconName='calendar' className='fs-5 me-2' />
                            Weekly
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={(e) => {
                            e.preventDefault()
                            handleScheduleSelect(agent.title, 'biweekly')
                            }}
                            active={schedules[agent.title] === 'biweekly'}  
                            className={schedules[agent.title] === 'biweekly' ? 'bg-light-primary' : ''}
                        >
                            <KTIcon iconName='calendar' className='fs-5 me-2' />
                            Every 2 Weeks
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={(e) => {
                            e.preventDefault()
                            handleScheduleSelect(agent.title, 'monthly')
                            }}
                            active={schedules[agent.title] === 'monthly'} 
                            className={schedules[agent.title] === 'monthly' ? 'bg-light-primary' : ''}
                        >
                            <KTIcon iconName='calendar' className='fs-6 me-2' />
                            Monthly
                        </Dropdown.Item>
                        {schedules[agent.title] && (
                            <>
                            <Dropdown.Divider />
                            <Dropdown.Item 
                                onClick={(e) => {
                                e.preventDefault()
                                handleScheduleSelect(agent.title, '')
                                }}
                                className="text-danger"
                            >
                                <KTIcon iconName='trash' className='fs-5 me-2' />
                                Remove Schedule
                            </Dropdown.Item>
                            </>
                        )}
                        </Dropdown.Menu>
                    </Dropdown>
                    )}
                  <div className="text-center mb-3">
                    <KTIcon
                      iconName={agent.icon}
                      iconType="duotone"
                      className="fs-3x text-primary"
                    />
                  </div>
                  <h3 className="fs-2 fw-bolder mb-2">{agent.title}</h3>
                  <p className="text-gray-600 mb-0">{agent.description}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <Modal
            show={showDocModal}
            onHide={() => setShowDocModal(false)}
            size="lg"
            dialogClassName="agent-doc-modal"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title className="fs-2">
                    <div className="d-flex align-items-center">
                        <KTIcon iconName='document' className='fs-1 text-primary me-4' />
                        {title}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="markdown-content" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                  {/*<ReactMarkdown components={{
                    h1: ({node, ...props}) => <h1 style={{fontSize: '1.8rem', marginBottom: '1rem'}} {...props}/>,
                    p: ({node, ...props}) => <p style={{marginBottom: '1rem'}} {...props}/>
                  }}>{markdownContent}</ReactMarkdown>*/}
                  <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </Modal.Body>
            <Modal.Footer>
                <button
                className="btn btn-primary"
                onClick={handleDownloadPDF}
                >
                <KTIcon iconName='file-down' className='fs-2 me-2' />
                Download PDF
                </button>
            </Modal.Footer>
            </Modal>
      </div>
    </div>
  )
  
}

export default LeximAgents
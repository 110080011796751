import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import { useAuth } from '../../modules/auth';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import PaginationWrapper from '../../components/PaginationWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import DocumentHeader from './Components/DocumentHeader';
import DocumentToolbar from './Components/DocumentToolbar';
import { useQuery, useQueryClient } from 'react-query';

const Document = ({ docType: docTypeParam = '' }) => {
    const queryClient = useQueryClient();
    const [documents, setDocuments] = useState([]);
    const { documentType } = useParams();
    const docType = docTypeParam || '';
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const moment = require('moment-timezone');
    const location = useLocation();
    const stateData = location.state || { agency: '', period_code: 0 };
    const [totalDocuments, setTotalDocuments] = useState(0);
    const cachedProgramCodes = useRef(null);

    // Define initial filter state
    const [filters, setFilters] = useState({
        document: '',
        program: '',
        startDate: stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '',
        endDate: '',
        tracked: documentType ? true : false,
        genAI: false,
        agency: stateData.agency || '',
    });

    // console.log(filters)

    // Add applied filters state that will only change when Apply is clicked
    const [appliedFilters, setAppliedFilters] = useState({ ...filters });

    const [searchInput, setSearchInput] = useState('');
    const { currentUser, logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [appliedSortConfig, setAppliedSortConfig] = useState({ ...sortConfig });
    const [sortDirection, setSortDirection] = useState(''); // Current sorting direction
    // const [appliedSortConfig, setAppliedSortConfig] = useState({...sortConfig});
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage || 1);
    const [trackedDocuments, setTrackedDocuments] = useState([]);

    // Wrap interests in useMemo to fix exhaustive-deps warning
    const interestsFromRedux = useSelector(state => state.account.interests);
    const interests = useMemo(() => interestsFromRedux || { agencies: [], programs: [] }, [interestsFromRedux]);

    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    const [data, setData] = useState([]);
    const [isInsightsVisible, setIsInsightsVisible] = useState(false);
    const searchTimeoutRef = useRef(null);

    // Determine API URL based on docType
    const api_url = docType === 'final-rules' ? 'finalrules'
        : docType === 'proposed-rules' ? 'proposedrules'
            : docType === 'guidances' ? 'guidance'
                : docType === 'notices' ? 'notice'
                    : 'finalrules';

    // Process interests to get program mappings
    useEffect(() => {
        if (interests && Array.isArray(interests.programs)) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }

        // Reset cached program codes when interests change
        cachedProgramCodes.current = null;

        return () => {
            // Cleanup
        };
    }, [interests]);

    // Format the filters for the API
    const prepareApiFilters = useCallback((filtersToUse) => {
        const apiFilters = {};

        // Handle agency filtering
        if (filtersToUse.agency.length) {
            apiFilters.agencies = filtersToUse.agency;
        } else if (interests.agencies && Array.isArray(interests.agencies) && interests.agencies.length > 0) {
            apiFilters.agencies = interests.agencies;
        }

        // Handle program filtering for FDA
        if (filtersToUse.program.length) {
            // If a specific program is selected in filters
            apiFilters.fda_programs = filtersToUse.program;
        } else if (interests.programs && Array.isArray(interests.programs) && interests.programs.length > 0) {
            // Extract program codes from interests - do this once and cache it
            if (!cachedProgramCodes.current) {
                cachedProgramCodes.current = interests.programs.map(program => {
                    const match = program ? program.match(/\((.*?)\)/) : undefined;
                    return match && match.length > 1 ? match[1] : program;
                }).filter(Boolean);
            }

            if (cachedProgramCodes.current.length > 0) {
                apiFilters.fda_programs = cachedProgramCodes.current;
            }
        }

        // Handle date filters
        if (filtersToUse.startDate) {
            apiFilters.modify_start_date = filtersToUse.startDate;
        }

        if (filtersToUse.endDate) {
            apiFilters.modify_end_date = filtersToUse.endDate;
        }

        // Handle GenAI filter
        if (filtersToUse.genAI) {
            apiFilters.ai_enabled = true;
        }

        // Handle document title filter
        if (filtersToUse.document) {
            apiFilters.title = filtersToUse.document;
        }

        return JSON.stringify(apiFilters);
    }, [interests]);

    // Insights data query - fixed to avoid the ESLint warning
    useQuery(
        'insights',
        () => axios.get('/reg/v1/home/insights/documents')
            .then(response => response.data)
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                }
                throw error;
            }),
        {
            onSuccess: (data) => setData(data),
            staleTime: 300000, // 5 minutes
            retry: 2
        }
    );

    // Create a stable string key for tracked documents query
    const trackedDocsQueryKey = `trackedDocuments-${api_url}-${currentUser?.id || 'null'}`;

    // Tracked documents query
    const { data: trackedDocs, refetch: refetchTrackedDocs, isLoading: isLoadingTrackedDocs, isFetching: isFetchingTrackedDocs } = useQuery(
        trackedDocsQueryKey,
        () => axios.get(`/reg/v1/document/user/${api_url}/${currentUser?.id}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log('Failed to fetch tracked documents', error);
                return [];
            }),
        {
            onSuccess: (data) => setTrackedDocuments(data),
            enabled: !!currentUser?.id && docType !== "notices",
            staleTime: 300000, // 5 minutes
            retry: 2,
        }
    );

    // Calculate offset based on current page and items per page
    const offset = (currentPage - 1) * itemsPerPage;

    // Add a debounced search handler
    useEffect(() => {
        // Clear any existing timeout
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Reset to page 1 when search changes
        setCurrentPage(1);

        // Set a new timeout for the search
        searchTimeoutRef.current = setTimeout(() => {
            // Invalidate the query to force a refetch with the new search input
            queryClient.invalidateQueries(['documents']);
        }, 500); // 500ms debounce

        return () => {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
        };
    }, [searchInput, queryClient]);

    // Effect to handle itemsPerPage changes
    useEffect(() => {
        // Check if itemsPerPage is invalid (negative or 0) and reset to 10 if so
        if (itemsPerPage < 10) {
            setItemsPerPage(10);
            Cookies.set('itemsPerPage', 10); // Save the default value to cookies
        } else if (itemsPerPage > 100) {
            setItemsPerPage(100)
            Cookies.set('itemsPerPage', 100);
        } else {
            Cookies.set('itemsPerPage', itemsPerPage); // Otherwise, save the valid value
        }


        // Reset to first page when items per page changes
        setCurrentPage(1);
        // Invalidate the query to force a refetch with the new itemsPerPage
        queryClient.invalidateQueries(['documents']);

        return () => {
            // Cleanup
        };
    }, [itemsPerPage, queryClient]);

    // Convert UI sort key to API sort key
    const convertSortKeyForApi = (uiKey) => {
        // Convert document_title to title for the API
        if (uiKey === 'document_title') {
            return 'title.keyword';
        }
        return uiKey;
    };

    // Create a stable query key that changes with the relevant state
    const createQueryKey = useCallback(() => {
        return [
            'documents',
            api_url,
            itemsPerPage,
            offset,
            appliedSortConfig.key,
            appliedSortConfig.direction,
            appliedFilters.document,
            appliedFilters.program,
            appliedFilters.startDate,
            appliedFilters.endDate,
            appliedFilters.tracked,
            appliedFilters.genAI,
            appliedFilters.agency,
            searchInput,
            currentPage
        ];
    }, [
        api_url,
        itemsPerPage,
        offset,
        appliedSortConfig.key,
        appliedSortConfig.direction,
        appliedFilters,
        searchInput,
        currentPage
    ]);

    // Get the current query key
    const queryKey = createQueryKey();

    // Main documents query with pagination, sorting, and filtering
    const { isLoading, isError, error: fetchError, isFetching } = useQuery(
        queryKey,
        () => {
            // setLoading(true);
            // For search, use the search endpoint
            // if ((searchInput || '').trim() !== '') {
            //     let type;
            //     if (docType === 'final-rules') {
            //         type = 'rule';
            //     } else if (docType === 'proposed-rules') {
            //         type = 'proposedrule';
            //     } else if (docType === 'guidances') {
            //         type = 'guidance';
            //     } else if (docType === 'notices') {
            //         type = 'notice';
            //     }

            //     return axios.post(`/reg/v1/search/${type}/search_es`,
            //         { query: searchInput },
            //         { headers: { 'Content-Type': 'application/json' } }
            //     )
            //         .then(response => ({
            //             documents: response.data,
            //             total_documents: response.data.length,
            //             documents_count: response.data.length
            //         }));
            // }

            // Prepare the request body
            const requestBody = {
                limit: itemsPerPage,
                offset: offset,
                filters: {}
            };

            // Add sorting if available
            if (appliedSortConfig && appliedSortConfig.key) {
                const apiSortKey = convertSortKeyForApi(appliedSortConfig.key);
                requestBody.sort = {
                    sort_col: apiSortKey,
                    sort_order: sortConfig.direction
                };
            }

            // Add search string if available
            if ((searchInput || '').trim() !== '') {
                requestBody.search_string = searchInput.trim();
            }

            // Prepare filters
            const apiFilters = JSON.parse(prepareApiFilters(appliedFilters));
            // Add all filters to request body
            Object.assign(requestBody.filters, apiFilters);

            // For normal document fetching with filters and sorting
            // const apiFilters = prepareApiFilters(appliedFilters);

            // Convert sortConfig.key for API if needed
            // const apiSortKey = convertSortKeyForApi(appliedSortConfig.key);

            // const sort = JSON.stringify({
            //     sort_col: apiSortKey,
            //     sort_order: sortConfig.direction
            // });

            // Handle tracked documents filter
            if (appliedFilters.tracked && docType !== "notices" && trackedDocs) {
                const trackedDocumentIds = trackedDocs
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);

                requestBody.filters.document_ids = trackedDocumentIds;

                // if (trackedDocumentIds.length > 0) {
                // Add tracked document IDs to the filters
                // const trackedFilters = apiFilters;
                // const trackedFilters = apiFilters;
                // trackedFilters.document_ids = trackedDocumentIds;
                // requestBody.filters.document_ids = trackedDocumentIds;

                // return axios.get(`/reg/v1/document/all/${api_url}?limit=${itemsPerPage}&offset=${offset}&filters=${JSON.stringify(trackedFilters)}&sort=${sort}`)
                // .then(response => response.data);
                // }
                // else {
                //     return { documents: [], total_documents: 0, documents_count: 0 };
                // }
            }

            console.log(requestBody)

            // Make the unified POST request
            return axios.post(
                `/reg/v1/document/all/${api_url}`,
                requestBody,
                { headers: { 'Content-Type': 'application/json' } }
            )
                .then(response => response.data)
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        logout();
                        navigate('/auth/login');
                    }
                    throw error;
                });
        },
        {
            // Only enable this query when either:
            // 1. User is not filtering for tracked documents, OR
            // 2. The tracked documents query has already completed
            enabled: !appliedFilters.tracked || docType === "notices" || !!trackedDocs,

            // Keep previous data while loading new data
            keepPreviousData: false,

            // Use previous query data as placeholder during loading
            // placeholderData: previousQueryData => previousQueryData,
            staleTime: 0, // Set to 0 to always refetch when the query key changes
            cacheTime: 5 * 60 * 1000, // Keep in cache for 5 minutes
            refetchOnWindowFocus: false, // Don't refetch on window focus
            refetchOnMount: true, // Always refetch when the component mounts
            retry: 2,
            onSuccess: (data) => {
                setDocuments(data.documents || []);
                setTotalDocuments(data.total_documents || 0);
                setTotalPages(Math.ceil((data.total_documents || 0) / itemsPerPage));
                // setLoading(false); // Ensure loading is set to false after data is fetched
            },
            onError: (error) => {
                setLoading(false); // Ensure loading is set to false on error
                setError(error);
            }
        }
    );

    useEffect(() => {
        const waitingForTrackedDocs = appliedFilters.tracked &&
            docType !== "notices" &&
            !trackedDocs;

        setLoading(isLoading || isLoadingTrackedDocs || isFetching || isFetchingTrackedDocs || waitingForTrackedDocs);
    }, [isLoading, isLoadingTrackedDocs, isFetching, isFetchingTrackedDocs, appliedFilters.tracked, docType, trackedDocs]);
    // Update loading state
    // useEffect(() => {
    //     setLoading(isLoading);

    //     return () => {
    //         // Cleanup
    //     };
    // }, [isLoading]);

    // Update error state
    useEffect(() => {
        if (isError) {
            setError(fetchError);
        } else {
            setError(null);
        }

        return () => {
            // Cleanup
        };
    }, [isError, fetchError]);

    // Reset to page 1 when applied filters or sorting changes
    useEffect(() => {
        setCurrentPage(1);

        return () => {
            // Cleanup
        };
    }, [appliedFilters, appliedSortConfig, itemsPerPage]);

    // Reset filters when docType changes
    useEffect(() => {
        const defaultFilters = {
            document: '',
            program: '',
            startDate: '',
            endDate: '',
            tracked: documentType ? true : false,
            genAI: false,
            agency: '',
        };

        setSearchInput('');  // Reset searchInput

        // Reset filters to default
        setFilters(defaultFilters);
        setAppliedFilters(defaultFilters);

        setSortConfig({ key: '', direction: '' }); // Reset sorting to default
        // setAppliedSortConfig(defaultSort); // Reset applied sorting to default
        setSortDirection('');
        setAppliedSortConfig({ key: '', direction: '' })

        setCurrentPage(1); // Reset to first page
        queryClient.invalidateQueries([trackedDocsQueryKey]);
        // Invalidate queries to force refetch
        queryClient.invalidateQueries(['documents']);

        return () => {
            // Cleanup
        };
    }, [docType, queryClient, trackedDocsQueryKey, documentType]);

    // Unified date formatting function
    const formatDate = useCallback((timestamp) => {
        if (!timestamp || (typeof timestamp === 'string' && isNaN(Date.parse(timestamp)))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    // Handle filter reset
    const handleResetFilters = () => {
        const resetFilters = {
            document: '',
            program: '',
            startDate: stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '',
            endDate: '',
            tracked: documentType ? true : false,
            genAI: false,
            agency: stateData.agency || '',
        };

        setFilters(resetFilters);
        setSearchInput(''); // Also clear search input when resetting filters

        // Apply the reset immediately
        setAppliedFilters(resetFilters);
        setCurrentPage(1);

        // Force refetch by invalidating current queries
        queryClient.invalidateQueries(['documents']);
    };

    // Combined function to apply all filtering and sorting changes
    const handleApplyAll = () => {
        setAppliedFilters({ ...filters });
        setSortConfig(sortConfig);
        setAppliedSortConfig({ ...sortConfig })
        setCurrentPage(1);

        // Force refetch by invalidating current queries
        queryClient.invalidateQueries(['documents']);
    };

    // Handle sort change
    const onApplySort = useCallback(() => {
        setSortConfig(sortConfig);
        setAppliedSortConfig({ ...sortConfig })
        // Don't apply sort immediately - will be applied when "Apply" is clicked
    }, [sortConfig]);

    // Reset sorting
    const onResetSort = useCallback(() => {
        setSortConfig({ key: '', direction: '' }); // Reset sorting configuration
        setAppliedSortConfig({ key: '', direction: '' })
        setSortDirection(''); // Reset sorting direction
    }, []);

    // Custom page change handler to ensure data is fetched
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Custom itemsPerPage change handler to ensure data is fetched
    const handleItemsPerPageChange = (newItemsPerPage) => {

        if (newItemsPerPage < 10) setItemsPerPage(10);
        else if (newItemsPerPage > 100) setItemsPerPage(100);
        else setItemsPerPage(newItemsPerPage);
        // No need to explicitly refetch here as the useEffect will handle it
    };

    // Row definitions for document list
    const topRow = 'document_title';
    const secondaryRow = [['Subtype', 'subtype'], ['Program', 'program']];
    const thirdRow = [['Document ID', 'id'], ['Docket ID', 'docketId']];
    const bottomRow = [['Modified Date', 'modifyDate', formatDate], ['Posted Date', 'postedDate', formatDate], ['Comment Start Date', 'commentStartDate', formatDate], ['Comment End Date', 'commentEndDate', formatDate]];
    const summaryRow = 'mini_summary';
    const links = ['', 'iddocument'];

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <DocumentHeader
                    docType={docType}
                    setIsInsightsVisible={setIsInsightsVisible}
                    isInsightsVisible={isInsightsVisible}
                    interests={interests}
                    programs={programs}
                    filters={filters}
                    colors={colors}
                    data={data}
                />
                <DocumentToolbar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    docType={docType}
                    filters={filters}
                    setFilters={setFilters}
                    handleApplyFilters={handleApplyAll}
                    handleResetFilters={handleResetFilters}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={handleItemsPerPageChange}
                    interests={interests}
                    programs={programs}
                />
                {error && <div className="error-message">{error.message || 'An error occurred'}</div>}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}

                {loading ? (
                    <HourGlass />
                ) : (
                    <>
                        {totalDocuments > 0 ? (
                            <div className="d-flex justify-content-between align-items-center px-6">
                                <div className="text-muted">
                                    {`Showing ${offset + 1} to ${Math.min(offset + documents.length, totalDocuments)} of ${totalDocuments} documents`}
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '47vh' }}>
                                <div className="text-center text-gray-700 fs-1 fw-bold">
                                    No documents found <br />
                                    <span className="text-gray-600 fs-3">
                                        {filters.tracked
                                            ? 'Begin bookmarking documents to view them here.'
                                            : 'Please adjust your search criteria or filters to refine your results.'}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DocumentListWrapper
                            filteredDocuments={documents}
                            topRow={topRow}
                            secondaryRow={secondaryRow}
                            thirdRow={thirdRow}
                            bottomRow={bottomRow}
                            summaryRow={summaryRow}
                            links={links}
                            linkTo={'id'}
                            formatDate={formatDate}
                            trackedDocuments={trackedDocuments}
                            setTrackedDocuments={setTrackedDocuments}
                            docType={docType}
                            setAlert={setAlert}
                            refetchTrackedDocs={refetchTrackedDocs}
                        />
                    </>
                )}

                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} bottomSpace={7} />
                        <PaginationWrapper
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={handlePageChange}
                        />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )}
            </div>
        </div>
    );
};

export default Document;
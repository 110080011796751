import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MixedWidget10, MixedWidget11 } from '../../../../_metronic/partials/widgets';

const DocumentHeader = ({
    docType,
    setIsInsightsVisible,
    isInsightsVisible,
    interests,
    programs,
    filters,
    colors,
    data
}) => {
    return (
        <>
            <div className="d-flex flex-row-fluid px-1">
                <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                    <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center ms-4">
                            <KTIcon iconName={docType === 'final-rules' ? 'file-added' : docType === 'debarment' ? 'message-text-2' : 'document'} iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>{docType === 'final-rules' ? 'Final Rules'
                                : docType === 'proposed-rules' ? 'Proposed Rules'
                                    : docType === 'guidances' ? 'Guidances'
                                        : docType === 'notices' ? 'Notices'
                                            : docType === 'debarment' ? 'Debarments'
                                                : docType === 'warning-letters' ? 'Warning Letters'
                                                    : 'Documents'}</span>
                        </div>
                    </h1>
                </div>
            </div>

            {(interests?.agencies || programs) && (
            <>
                <div className="d-flex flex-row-fluid px-10 align-items-center justify-content-between ">
                <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                {isInsightsVisible && (
                    <button 
                    type="button" 
                    className="btn btn-sm btn-light-primary btn-active-light border border-1" 
                    onClick={() => setIsInsightsVisible(!isInsightsVisible)}
                    >
                    <KTIcon iconName='chart-simple' className='fs-2' />
                    {isInsightsVisible ? 'Hide' : 'Show'} Insights
                    </button>
                )}
                </div>
                
                <div className="d-flex flex-row-fluid px-10">
                {interests?.agencies?.length > 0 && (
                    <div style={{ position: 'relative', paddingRight: '7px' }}>
                    <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                    {programs && Object.keys(programs).length > 0 && (
                        <div style={{
                        position: 'absolute',
                        right: 0,
                        top: '5%',
                        bottom: '5%',
                        width: '1px',
                        backgroundColor: '#4d4d4d'
                        }}></div>
                    )}
                    </div>
                )}
                {programs && Object.keys(programs).length > 0 && (
                    <div style={{ paddingLeft: interests?.agencies?.length === 0 ? '0px' : '7px' }}>
                    <h6 className={`d-md-block text-wrap mt-1`}>FDA Programs: {' '}
                        {Object.entries(programs).map(([key, value], index) => (
                        <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                            <Tooltip id={`tooltip-${index}`}>
                                {value}
                            </Tooltip>
                            }
                        >
                            <span>{index > 0 && ', '}{key}</span>
                        </OverlayTrigger>
                        ))}
                    </h6>
                    </div>
                )}
                </div>
            </>
            )}
            {/* <div className="d-flex flex-row-fluid px-10 align-items-center justify-content-between ">
                <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                {isInsightsVisible && (
                    <button type="button" className="btn btn-sm btn-light-primary btn-active-light border border-1" onClick={() => setIsInsightsVisible(!isInsightsVisible)}>
                        <KTIcon iconName='chart-simple' className='fs-2' />
                        {isInsightsVisible ? 'Hide' : 'Show'} Insights
                    </button>
                )}
            </div>
            {interests?.agencies && programs &&
                <div className="d-flex flex-row-fluid px-10">
                    {interests.agencies.length > 0 && (
                        <div style={{ position: 'relative', paddingRight: '7px' }}>
                            <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                            {Object.keys(programs).length > 0 && (
                                <div style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '5%',
                                    bottom: '5%',
                                    width: '1px',
                                    backgroundColor: '#4d4d4d'
                                }}></div>
                            )}
                        </div>
                    )}
                    {Object.keys(programs).length > 0 && (
                        <div style={{ paddingLeft: interests.agencies.length === 0 ? '0px' : '7px' }}>
                            <h6 className={`d-md-block text-wrap mt-1`}>FDA Programs: {' '}
                                {Object.entries(programs).map(([key, value], index) => (
                                    <OverlayTrigger
                                        key={index}
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${index}`}>
                                                {value}
                                            </Tooltip>
                                        }
                                    >
                                        <span>{index > 0 && ', '}{key}</span>
                                    </OverlayTrigger>
                                ))}
                            </h6>
                        </div>
                    )}
                </div>
            } */}
            <div className="d-flex flex-column px-10 align-items-start justify-content-between">
                {(filters.tracked || filters.document
                    || (filters.program && filters.program.length) || filters.startDate
                    || filters.endDate || (filters.agency && filters.agency.length)
                    || filters.genAI || filters.name
                    || filters.response || filters.closeout
                ) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filters.name && <li><h6 className="d-md-block text-wrap">Name: {filters.name}</h6></li>}
                                {filters.tracked && <li><h6 className="d-md-block text-wrap">My Bookmarks</h6></li>}
                                {filters.genAI && <li><h6 className="d-md-block text-wrap">GenAI Enabled Documents</h6></li>}
                                {filters.document && <li><h6 className="d-md-block text-wrap">Document Title: {filters.document}</h6></li>}
                                {/* {filters.agency && <li><h6 className="d-md-block text-wrap">Agency: {filters.agency}</h6></li>} */}
                                {filters.agency && filters.agency.length > 0 && (
                                <li>
                                    <h6 className="d-md-block text-wrap">
                                    Agency: {filters.agency.join(", ")}
                                    </h6>
                                </li>
                                )}

                                {/* {filters.program && <li><h6 className="d-md-block text-wrap">Program: {filters.program}</h6></li>} */}
                                {filters.program && filters.program.length > 0 && (
                                <li>
                                    <h6 className="d-md-block text-wrap">
                                    Program: {filters.program.join(", ")}
                                    </h6>
                                </li>
                                )}
                                {filters.response && <li><h6 className="d-md-block text-wrap">Contains Response Letter</h6></li>}
                                {filters.closeout && <li><h6 className="d-md-block text-wrap">Contains Closeout Letter</h6></li>}
                                {(filters.startDate || filters.endDate) && (
                                    <li>
                                        <h6 className="d-md-block text-wrap">
                                            Comment Date {filters.startDate && filters.endDate ? 'Range' : (filters.startDate ? 'Start' : 'End')}: {' '}
                                            {filters.startDate ? filters.startDate : ''}
                                            {filters.startDate && filters.endDate ? ' to ' : ''}
                                            {filters.endDate ? filters.endDate : ''}
                                        </h6>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
            </div>
            {isInsightsVisible && docType !== 'warning-letters' && (
                <div className='row g-5 justify-content-center mt-1 mb-4 px-10'>
                    <div className='col-xxl-4'>
                        <MixedWidget11
                            className='card-xxl-stretch mb-xl-3'
                            chartColor={colors}
                            chartHeight='250px'
                            data={data}
                            title="Total Documents By Agency"
                            columns={{
                                "Total Documents": "doc_total"
                            }}
                            activity={"all"}
                        />
                    </div>
                    <div className='col-xxl-4'>
                        <MixedWidget10
                            className='card-xxl-stretch mb-xl-3'
                            chartColor={colors}
                            chartHeight='250px'
                            data={data}
                            title="FDA Documents By Type"
                            columns={docType === 'guidances' ?
                                {
                                    "Other": "other_total"
                                }
                                : docType === 'notices' || docType === 'debarment' ?
                                    {
                                        "Notices": "notice_total",
                                        "Other": "other_total"
                                    }
                                    : {
                                        "Proposed Rules": "proposed_total",
                                        "Final Rules": "rule_total",
                                    }}
                            agency="FDA"
                            activity={"all"}
                        />
                    </div>
                    <div className='col-xxl-4'>
                        <MixedWidget10
                            className='card-xxl-stretch mb-xl-3'
                            chartColor={colors}
                            chartHeight='250px'
                            data={data}
                            title="CMS Documents By Type"
                            columns={docType === 'guidances' ?
                                {
                                    "Other": "other_total"
                                }
                                : docType === 'notices' || docType === 'debarment' ?
                                    {
                                        "Notices": "notice_total",
                                        "Other": "other_total"
                                    }
                                    : {
                                        "Proposed Rules": "proposed_total",
                                        "Final Rules": "rule_total",
                                    }}
                            agency="CMS"
                            activity={"all"}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default DocumentHeader;

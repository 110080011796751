import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { KTIcon } from '../../../_metronic/helpers';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import { formatCountry, formatDateInternational } from './utils';
import { fetchDocuments, fetchTrackedDocuments } from './apiService';
// import { applySort } from './sortUtils';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import PaginationWrapper from '../../components/PaginationWrapper';
import DocumentToolbar from '../Document/Components/DocumentToolbar';

function Country({ country, region_code }) {
    // State management for search input, pagination, sorting, and alerts
    const [searchInput, setSearchInput] = useState(''); // Stores the search input value
    const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
    const [sortConfig, setSortConfig] = useState({ key: 'publish_date', direction: 'desc' }); // Sorting configuration (key and direction)
    const [appliedSortConfig, setAppliedSortConfig] = useState({ ...sortConfig });
    const [sortDirection, setSortDirection] = useState('desc'); // Current sorting direction
    const [alert, setAlert] = useState({ message: '', type: '' }); // Alert message and type (e.g., success, error)

    // Authentication and routing hooks
    const { currentUser } = useAuth(); // Current authenticated user
    const location = useLocation(); // Access the current route location
    const { documentType, country: urlCountry } = useParams(); // URL parameters

    // Mapping of URL country codes to their corresponding formatted country names
    const countryMap = {
        "australia": "Australia",
        "china": "China",
        "south-korea": "South Korea",
        "european-union": "EU",
        // Add more mappings as needed
    };

    const givenCountry = region_code || countryMap[urlCountry] || location.state?.givenCountry || ''; // Determine the country from URL or props

    // Fetch documents using React Query
    const { data: events, isLoading, error } = useQuery(
        ['documents', givenCountry, searchInput, appliedSortConfig], // Query key
        () => fetchDocuments(givenCountry, searchInput, appliedSortConfig), // Fetch function
        {
            // select: (data) => applySort(data, sortConfig), // Sort data after fetching
        }
    );

    // Fetch tracked documents for the current user using React Query
    const { data: trackedDocuments } = useQuery(
        ['trackedDocuments', currentUser?.id], // Query key
        () => fetchTrackedDocuments(currentUser?.id), // Fetch function
        {
            enabled: !!currentUser?.id, // Only fetch if the user is authenticated
        }
    );

    // Update the displayed events based on pagination
    const updateShowEvents = useCallback(() => {
        const initialIndex = (currentPage - 1) * itemsPerPage; // Calculate the starting index for the current page
        const lastIndex = initialIndex + itemsPerPage; // Calculate the ending index for the current page
        return events?.slice(initialIndex, lastIndex) || []; // Slice the events array to display only the current page's items
    }, [currentPage, itemsPerPage, events]);

    const showEvents = updateShowEvents(); // Events to display on the current page

    // Apply sorting and update displayed events
    const onApplySort = useCallback(() => {
        setAppliedSortConfig({...sortConfig}); // Update the sorting configuration
    }, [sortConfig]);

    // Reset sorting to default (publish_date in descending order)
    const onResetSort = useCallback(() => {
        setSortConfig({ key: 'publish_date', direction: 'desc' }); // Reset sorting configuration
        setSortDirection('desc'); // Reset sorting direction
        setAppliedSortConfig({ key: 'publish_date', direction: 'desc' });
    }, []);

    // Reset search input and sorting when the country changes
    React.useEffect(() => {
        setSearchInput(''); // Clear the search input
        setSortConfig({ key: 'publish_date', direction: 'desc' }); // Reset sorting to default
        setSortDirection('desc'); // Reset sorting direction
    }, [country]);

    // Reset current page to 1 when the country or search input changes
    // React.useEffect(() => {
    //     setCurrentPage((prevPage) => (prevPage !== 1 ? 1 : prevPage)); // Reset to the first page
    // }, [country, searchInput]);

    const handleItemsPerPageChange = (newItemsPerPage) => {

        if (newItemsPerPage < 10) setItemsPerPage(10);
        else if (newItemsPerPage > 100) setItemsPerPage(100);
        else setItemsPerPage(newItemsPerPage);
    };

    // Define table row configurations for the document list
    const topRow = 'title'; // Primary row displays the document title
    const secondaryRow = [['Document Type', 'documentType']]; // Secondary row displays the document type
    const thirdRow = !givenCountry
        ? [['Country', 'country'], ['Agency', 'long_name']] // If no country is given, display country and agency
        : [['Agency', 'long_name']]; // Otherwise, display only the agency
    const bottomRow = [['Publish Date', 'publish_date', formatDateInternational]]; // Bottom row displays the formatted publish date
    const summaryRow = 'mini_summary'; // Summary row displays a brief summary of the document
    const links = ['', 'id']; // Links for navigation (e.g., to the document details page)

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                {/* Header section with country name and icon */}
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary' />
                                <span>{formatCountry(country) || 'My International Documents'}</span>
                            </div>
                        </h1>
                    </div>
                </div>

                {/* Document toolbar for search, sorting, and pagination controls */}
                <DocumentToolbar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    docType={'international'}
                    placeholder={`Search ${formatCountry(country) || 'My International'} Documents`}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={handleItemsPerPageChange}
                />

                {/* Display error messages if any */}
                {error && <div className="error-message">{error.message}</div>}

                {/* Display alert messages (e.g., success, error) */}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}

                {/* Display loading spinner while data is being fetched */}
                {isLoading ? (
                    <HourGlass />
                ) : (
                    // Display the document list once data is loaded
                    <DocumentListWrapper
                        filteredDocuments={showEvents}
                        topRow={topRow}
                        secondaryRow={secondaryRow}
                        thirdRow={thirdRow}
                        bottomRow={bottomRow}
                        summaryRow={summaryRow}
                        linkTo={'doc_id'}
                        links={links}
                        isInternational={true}
                        trackedDocuments={trackedDocuments || []}
                        setTrackedDocuments={() => {}}
                        setAlert={setAlert}
                        state={{ currentPage: currentPage, searchInput: searchInput, givenCountry: givenCountry, myInternational: documentType === 'international' }}
                    />
                )}

                {/* Display pagination controls if there are multiple pages */}
                {!isLoading && events?.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={7} />
                        <PaginationWrapper totalPages={Math.ceil(events.length / itemsPerPage)} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Country;
import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {

    const specialty_mappings = {
        "Anesthesiology": "AN",
        "Cardiovascular": "CV",
        "Clinical Chemistry": "CH",
        "Dental": "DE",
        "Ear, Nose, Throat": "EN",
        "Gastroenterology, Urology": "GU",
        "General Hospital": "HO",
        "Hematology": "HE",
        "Immunology": "IM",
        "Medical Genetics": "MG",
        "Microbiology": "MI",
        "Neurology": "NE",
        "Obstetrics/Gynecology": "OB",
        "Ophthalmic": "OP",
        "Orthopedic": "OR",
        "Pathology": "PA",
        "Physical Medicine": "PM",
        "Radiology": "RA",
        "General, Plastic Surgery": "SU",
        "Clinical Toxicology": "TX",
        "Unknown": "UN"
    }
    return (

        <div className='table-responsive px-5 mb-6'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '14%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('res_event_number')}>
                            Event Number
                            <span className={`sorting-icon ${sortConfig.key === 'res_event_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'res_event_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th style={{ whiteSpace: 'nowrap', width: '25%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.device_name.keyword');
                                }
                            }}
                        >
                            Device Name
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.device_name.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.device_name.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('recalling_firm.keyword');
                                }
                            }}
                        >
                            Recalling Firm
                            <span className={`sorting-icon ${sortConfig.key === 'recalling_firm.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recalling_firm.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '13%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.device_class.keyword');
                                }
                            }}
                        >
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.device_class.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.device_class.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '10%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.medical_specialty_description.keyword');
                                }
                            }}
                        >
                            
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.medical_specialty_description.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.medical_specialty_description.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '13%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('product_code.keyword');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        

                        
                        <th style={{ whiteSpace: 'nowrap', width: '18%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('root_cause_description.keyword');
                                }
                            }}
                        >
                            Root Cause
                            <span className={`sorting-icon ${sortConfig.key === 'root_cause_description.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'root_cause_description.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '9%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('recall_status')}>
                            Status
                            <span className={`sorting-icon ${sortConfig.key === 'recall_status' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recall_status' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className='text-center' style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '10px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('product_res_number.keyword');
                                }
                            }}
                        >
                            Recall Number
                            <span className={`sorting-icon ${sortConfig.key === 'product_res_number.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_res_number.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-end ${sortConfig.key !== 'event_date_posted' ? 'pe-5' : ''}`} style={{ whiteSpace: 'nowrap', width: '11%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('event_date_posted');
                                }
                            }}
                        >
                            Date Posted
                            <span className={`sorting-icon ${sortConfig.key === 'event_date_posted' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'event_date_posted' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '10%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((recall, index) => (
                                <tr className='align-items-center bg-hover-light-primary' 
                                onClick={() => 
                                // navigate(`/device-intelligence/recalls/${recall.product_res_number}`, { state: { searchTerm: searchTerm, currentPage: currentPage } })}
                                window.open(`/device-intelligence/recalls/${recall.product_res_number}`, '_blank')
                            }
                                
                                >
                                    {/* <td style={{ verticalAlign: 'middle' }} className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {recall.res_event_number}
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.openfda.device_name}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.recalling_firm}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.openfda.device_class}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            { specialty_mappings[recall.openfda.medical_specialty_description] }
                                        </a>
                                    </td>
                                    <td className="text-center text-dark text-wrap fw-semibold fs-6 ps-4" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/classification/${recall.product_code}`} target="_blank" style={{ color: 'primary' }} className='align-items-center'>{recall.product_code}</Link>
                                    </td>
                                    
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.root_cause_description}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.product_res_number}
                                        </a>
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.recall_status}
                                        </a>
                                    </td> */}
                                    <td className='text-end'>
                                        <a href='/device-intelligence/recalls' className={`text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'event_date_posted' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(recall.event_date_posted).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link
                                            to={`/device-intelligence/recalls/${recall.product_res_number}`}
                                            state={{ searchTerm: searchTerm, currentPage: currentPage }}
                                            style={{ color: 'primary' }}
                                            target='_blank'
                                        >
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;
import React, { FC, useEffect } from 'react'
import Document from './Document'
import { useLocation, useNavigate } from 'react-router-dom';

interface DocumentWrapperProps {
    docType: string;
}

const DocumentsWrapper: FC<DocumentWrapperProps> = ({ docType = '' }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Clear searchInput in location.state when the component mounts
        if (location.state && location.state.searchInput) {
            navigate(location.pathname, { replace: true, state: { ...location.state, searchInput: '' } });
        }
    }, [docType, navigate, location.pathname, location.state]);

    return (
        <>
            <Document docType={docType} />
        </>
    )
}

export default DocumentsWrapper
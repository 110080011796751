import clsx from 'clsx'
import { KTIcon } from '../../../helpers'
// import { HeaderNotificationsMenu } from '../../../partials' // Uncomment for notifications
import { HeaderUserMenu, } from '../../../partials'
// import { Search } from '../../../partials'
import { useLayout } from '../../core'
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../../helpers'

// -- Uncomment for notifications --
// import { useState } from "react";
// import axios from 'axios';
// import { useAuth } from '../../../../app/modules/auth';
// import { useEffect } from "react";
// import { useNavigate } from 'react-router-dom'

const itemClass = 'ms-1 ms-md-4'
// const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px' // Uncomment for notifications
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
function mapStateToProps(state) {
  return { avatarUrl: state.account.avatarUrl };
}
const Navbar = (props) => {
  const avatarUrl = (props.avatarUrl && props.avatarUrl !== "none") ? props.avatarUrl : toAbsoluteUrl('/media/avatars/blank.png');
  const { config } = useLayout()

  // -- Uncomment for notifications --
  // const [announcements, setAnnouncements] = useState([]);

  // const { currentUser, logout } = useAuth();
  // const orgId = currentUser?.organization;
  // const navigate = useNavigate();
  // const userId = currentUser ? currentUser?.id : null;
    //     useEffect(() => {
    //     axios.get(`/reg/v1/user/announcement/${orgId}/${userId}`)
    //         .then(response => {
    //             const sortedData = response.data;
    //             setAnnouncements(sortedData);
    //         })
    //         .catch(error => {
    //             if (error.response && error.response.status === 401) {
    //                 logout();
    //                 navigate('/auth/login')
    //             }
    //             console.error('There was an error!', error);
    //         });
    // }, [userId, navigate, logout, orgId]);


  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        > */}
        {/* <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          // className={btnClass}
          className={clsx('position-relative', btnClass)}
        >
          // -- Uncomment for notifications --
          
          <KTIcon iconName='notification-on' className={btnIconClass} />
          {announcements.length > 0 && (
            <>
              <span className="badge badge-square badge-success h-10px w-10px position-absolute top-0 end-0">
                {announcements.length}
              </span> */}
              {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
            {/* </>
          )}

        </div> */}
        {/* <HeaderNotificationsMenu /> */}

        {/* -- Uncomment for notifications -- */}
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* </div> */}
      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
      </div>







      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={avatarUrl} alt='' />
        </div>
        <HeaderUserMenu avatarUrl={avatarUrl} />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}



export default connect(mapStateToProps)(Navbar);
// export { Navbar }

import React from 'react'

const CalendarCard = ({ event, index, formatDate }) => {
  return (
    <div className="col-md-12" key={index}>
        <div className="card card-custom card-border  m-2" key={index} style={{ backgroundColor: '', border: '1px solid #D6D7D9' }}>

            <div className="card-body ">

                <h5 className="card-title text-muted pb-2" style={{ textTransform: 'uppercase' }} >{event.documentType}</h5>
                <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}> Comments due: {formatDate(event.commentEndDate)}</h5>

                <h5 className="card-text pb-2" style={{ color: '#333333' }}>
                    {event.title}
                </h5>
                <div className="d-flex align-items-center">
                    <h5 className="card-title text-gray-700 pb-2">Agency: <span className="text-gray-600">{event.agencyId}</span></h5>
                    <span className="card-title text-gray-700 pb-2 px-2">|</span>

                    <h5 className="card-title text-gray-700 pb-2">Program: <span className="text-gray-600">{event.program}</span></h5>
                    <span className="card-title text-gray-700 pb-2 px-2">|</span>

                    <h5 className="card-title pb-2 text-gray-700">Document ID: <span className="text-gray-600">{event.id}</span></h5>
                    <span className="card-title text-gray-700 pb-2 px-2">|</span>
                    <h5 className="card-title text-gray-700 pb-2">
                        Docket ID:{' '}
                        <a 
                            href={`https://www.regulations.gov/docket/${event.docketId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={{ color: '#0077b3', textDecoration: 'underline' }}
                        >
                                {event.docketId}
                        </a>
                    </h5>
                    <span className="card-title text-gray-700 pb-2 px-2">|</span>
                    <h5 className="card-title text-gray-700 pb-2">Posted: <span className="text-gray-600">{formatDate(event.postedDate)}</span></h5>
                </div>


            </div>
        </div>
    </div>
  )
}

export default CalendarCard
import React from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {

    const tableMap = {
        'Registration Name': 'registration.name',
        'Agent Name': 'registration.us_agent.business_name',
        'Address 1': 'registration.address_line_1.keyword',
        'Adress 2': 'registration.address_line_2.keyword',
        'City': 'registration.city',
        'State': 'registration.state_code',
        'Zipcode': 'registration.zip_code',
        'Country': 'registration.iso_country_code',
    }

    // const sortConfigMap = {
    //     'k_pma_number': 'k_pma_number',
    //     'device_name': 'device_name',
    //     'device_class': 'device_class',
    //     'speciality': 'speciality',
    //     'product_code': 'product_code',
    //     'registration_date': 'registration_date',
    //     'last_updated': 'last_updated',
    //     'status': 'status'
    // }

    // const tableHeaders = ['k_pma_number', 'device_name', 'device_class', 'speciality', 'product_code', 'registration_date', 'last_updated', 'status'];
    // const navigate1 = useNavigate();
    return (
        <div className='table-responsive px-5 mb-6'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        <th style={{ whiteSpace: 'nowrap', width: '35%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Registration Name']);
                                }
                            }}
                        >
                            Registrant Name
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Registration Name'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Registration Name'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-start" style={{ whiteSpace: 'nowrap', width: '35%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Agent Name']);
                                }
                            }}
                        >
                            Agent Firm
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Agent Name'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Agent Name'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '25%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Address 1']);
                                }
                            }}
                        >
                            Address 1
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Address 1'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Address 1'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Adress 2']);
                                }
                            }}
                        >
                            Address 2
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Adress 2'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Adress 2'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '12%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['City']);
                                }
                            }}
                        >
                            City
                            <span className={`sorting-icon ${sortConfig.key === tableMap['City'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['City'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-center ${sortConfig.key !== 'state_code' ? 'pe-3' : ''}`} style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['State']);
                                }
                            }}
                        >
                            State
                            <span className={`sorting-icon ${sortConfig.key === tableMap['State'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['State'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '5%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Zipcode']);
                                }
                            }}
                        >
                            Zipcode
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Zipcode'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Zipcode'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '5%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(tableMap['Country']);
                                }
                            }}
                        >
                            Country
                            <span className={`sorting-icon ${sortConfig.key === tableMap['Country'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === tableMap['Country'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '10%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort ? (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {currentDevices.length === 0 ? (
                            <tr>
                                <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                            </tr>
                        ) : (
                            currentDevices.map((device, index) => (
                                <tr className='align-items-center bg-hover-light-primary'
                                    onClick={(e) => { e.stopPropagation();
                                        // navigate(`/device-intelligence/registration-details/${device._id}`);
                                        window.open(`/device-intelligence/registration-details/${device._id}`, '_blank');

                                        }}
                                >
                                    <td className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {device._source.registration.name}
                                    </td>
                                    <td className="text-dark text-wrap fw-semibold pe-5 fs-6" onClick={(e) => e.stopPropagation()}>
                                        {/* <Link to={`/device-intelligence/classification/${device.product_code}`} style={{ color: 'primary' }} target="_blank" >
                                            
                                        </Link> */}
                                        {device._source.registration.us_agent.business_name}
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/registration' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.registration.address_line_1}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.registration.address_line_2}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.registration.city}
                                        </a>
                                    </td>

                                    <td>
                                        <a href='/device-intelligence/registration' className={`text-center text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'state_code' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(device.state_code).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                            {device._source.registration.state_code}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-center text-dark text-wrap fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.registration.zipcode}
                                        </a>
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/registration' className={`text-center text-dark text-wrap fw-semibold d-block fs-6 ${sortConfig.key === 'product_release_date' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {/* {new Date(device.product_release_date).toLocaleDateString('en-US', { timeZone: 'UTC' })} */}
                                            {device._source.registration.iso_country_code}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-primary text-wrap fw-semibold fs-6' style={{ cursor: 'default' }}
                                        onClick={(e) => { e.stopPropagation(); 
                                        // navigate(`/device-intelligence/registration-details`, { state: { device: device, searchTerm: searchTerm, currentPage: currentPage } }); 
                                        }}>
                                        <Link 
                                            to={{ 
                                                pathname: `/device-intelligence/registration-details/${device._id}`, 
                                                state: { device: device, searchTerm: searchTerm, currentPage: currentPage } }
                                            } 
                                            target="_blank" style={{ color: 'primary' }}>
                                            Details
                                        </Link>
                                        {/* <span style={{ color: 'primary', cursor: 'pointer' }}>Details</span>  */}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;
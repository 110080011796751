export const bookmarksItems = [
    { icon: 'document', to: '/myitems/proposed-rules', title: 'Proposed Rules', hasArrow: false },
    { icon: 'file-added', to: '/myitems/final-rules', title: 'Final Rules', hasArrow: false },
    { icon: 'folder', to: '/myitems/guidances', title: 'Guidance', hasArrow: false },
    // { icon: 'map', to: '/myitems/international', title: 'International', hasArrow: false },
    //{ icon: 'document', to: '/myitems/notice/track', title: 'Notices', hasArrow: true }
];

export const codeOfRegulationsItems = [
    { icon: 'folder', to: '/cor/title-21', title: 'Title 21', hasArrow: true },
    { icon: 'folder', to: '/cor/title-42', title: 'Title 42', hasArrow: true }
];

export const legislationItems = [
    { icon: 'book-square', to: '/acacomingsoon', title: 'Affordable Care Act', hasArrow: true },
    { icon: 'home-2', to: '/sacomingsoon', title: 'Sunshine Act', hasArrow: true },
    { icon: 'geolocation-home', to: '/iracomingsoon', title: 'Inflation Reduction Act', hasArrow: true }
];

export const insightsItems = [
    { icon: 'chart', to: '/insights', title: 'Insights', hasArrow: false },
    //{ icon: 'chart', to: '/insights/historical', title: 'Historical Insights', hasArrow: true },
    //{ icon: 'chart', to: '/proposedcomingsoon', title: 'Proposed Rules', hasArrow: true },
    //{ icon: 'chart', to: '/rulescomingsoon', title: 'Rules', hasArrow: true },
    //{ icon: 'chart', to: '/commentcomingsoon', title: 'Comments', hasArrow: true }
];

export const appsItems = [
    { icon: 'folder-up', to: '/apps/lexim-cabinet', title: 'Lexim Cabinet', hasArrow: false },
    { icon: 'briefcase', to: '/apps/lexim-workbench', title: 'Lexim Workbench', hasArrow: false },
    { icon: 'printer', to: '/apps/translation', title: 'Translate', hasArrow: false },
    //{ icon: 'sort', to: '/docdiffcomingsoon', title: 'Doc Diff', hasArrow: true },
    //{ icon: 'filter-search', to: '/labelcomingsoon', title: 'Label Search', hasArrow: true }
];

export const resourcesItems = [
    { icon: 'geolocation', to: '/links/USA', title: 'US', hasArrow: false },
    { icon: 'geolocation', to: '/links/EU', title: 'EU', hasArrow: false },
    { icon: 'geolocation', to: '/links/all', title: 'All', hasArrow: false },
    //{ icon: 'share', to: '/comingsoon', title: 'Other', hasArrow: true }
];

export const adminItems = [
    { icon: 'profile-user', to: '/admin/users', title: 'Users', hasArrow: false },
    { icon: 'gear', to: '/admin/settings', title: 'Settings', hasArrow: false },
    { icon: 'category', to: '/admin/preferences', title: 'Preferences', hasArrow: false },
    { icon: 'add-item', to: '/admin/add-ons', title: 'Add-Ons', hasArrow: false },
    { fontIcon: 'bi-headset', to: '/admin/support', title: 'Support', hasArrow: false },
    { icon: 'message-notif', to: '/admin/announcements', title: 'Announcements', hasArrow: false },
];

export const superAdminItems = [
    { fontIcon: 'bi-buildings-fill', to: '/super-admin/organizations', title: 'Organizations', hasArrow: false },
    { icon: 'profile-user', to: '/super-admin/users', title: 'Users', hasArrow: false },
    { icon: 'gear', to: '/super-admin/settings', title: 'Settings', hasArrow: false },
    { fontIcon: 'bi-headset', to: '/super-admin/support', title: 'Support', hasArrow: false },
    { icon: 'message-notif', to: '/super-admin/announcements', title: 'Announcements', hasArrow: false },
    { icon: 'wrench', to: '/super-admin/configurations', title: 'Configuration', hasArrow: false },
    { icon: 'shield', to: '/super-admin/data-operations', title: 'Data Operations', hasArrow: false },
    { icon: 'file-up', to: '/super-admin/upload-warning-letters', title: 'Upload Warning Letters', hasArrow: false },
    { icon: 'chart-simple', to: '/super-admin/metrics', title: 'Metrics', hasArrow: false },
];
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { KTIcon } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import axios from 'axios';
import PaginationWrapper from '../../../components/PaginationWrapper';
import Modal from 'react-modal';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';
import MyDocumentsToolbar from './MyDocumentsToolbar';
import UploadSuccessModal from '../Modals/UploadSuccessModal';
import MyDocumentsTable from './MyDocumentsTable';
import clsx from 'clsx';
import DocDiffTable from '../DocDiff/DocDiffTable';
// import CompareDocumentModal from '../Modals/CompareDocumentDetails';
import MoveFolderModal from '../Modals/MoveFolderModal';
import FolderSuccessModal from '../Modals/FolderSuccessModal';
import HourGlass from '../../Loading/HourGlassSpinner/HourGlass';

Modal.setAppElement('#root');
const PAGINATION_PAGES_COUNT = 10;
//const API_URL = 'http://127.0.0.1:8000';

export interface File {
    id: number;
    type: string;
    folder: string;
    parent_id: number | null;
    name: string;
    uploaded_by: string;
    description: string;
    s3_link: string;
    create_date: Date;
    doc_name: string;
    is_genai_available: number;
    mini_summary: string;
    in_elastic: Boolean;
    translation_required: Boolean;
    files: {
        id: number;
        type: string;
        folder: string;
        parent_id: number | null;
        name: string;
        uploaded_by: string;
        description: string;
        s3_link: string;
        create_date: Date;
        doc_name: string;
        is_genai_available: number;
        in_elastic: Boolean;
        translation_required: Boolean;
        mini_summary: string;
    }[];
}

const MyDocuments = () => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchInput, setSearchInput] = useState('');
    const [filteredFiles, setFilteredFiles] = useState(uploadedFiles);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [isUploaded, setIsUploaded] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingFile, setEditingFile] = useState<File | null>(null);
    const [totalPages, setTotalPages] = useState(Math.ceil(uploadedFiles.length / PAGINATION_PAGES_COUNT));
    const [description, setDescription] = useState(editingFile ? editingFile.doc_name : '');
    const [name, setName] = useState(editingFile ? editingFile.name : '');
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [prevMessage, setPrevMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [diffFiles, setDiffFiles] = useState([]);
    const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 'uploadDocuments');
    const [showAlert, setShowAlert] = useState(false);
    const [diffToDelete, setDiffToDelete] = useState<{} | null>(null);
    const [diffDeleteConfirmation, setDiffDeleteConfirmation] = useState(false);
    const [prevFolder, setPrevFolder] = useState<{ [key: string]: number | null } | null>(
        location?.state?.prevFolder || null
    );
    const [selectedFolder, setSelectedFolder] = useState<{ [key: string]: number | null } | null>(
        location?.state?.selectedFolder || { "All Documents": null }
    );
    const [modalMessage, setModalMessage] = useState('');
    const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
    const [showMoveSuccessModal, setShowMoveSuccessModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [orgS3Path, setOrgS3Path] = useState('');
    const [selectedFolderLink, setSelectedFolderLink] = useState(orgS3Path || '');
    const [moveFile, setMoveFile] = useState<File[]>([]);

    const [documentToDelete, setDocumentToDelete] = useState<File | null>(null);
    const [filters, setFilters] = useState<{ [key: string]: string }>({
        fileName: '',
        startDate: '',
        endDate: '',
    });
    const startIndex = (currentPage - 1) * PAGINATION_PAGES_COUNT;
    const endIndex = startIndex + PAGINATION_PAGES_COUNT;

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    useEffect(() => {
        axios.get(`/reg/v1/user/org_vault_s3_path`).then(response => {
            setOrgS3Path(response.data.path);
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }, [currentUser?.organization]);

    const fetchFiles = useCallback(() => {
        setIsLoading(true);
        setSelectedFiles([]);

        // Helper function to find the parent folder recursively
        // const findAndAddToParentFolder = (folders: any[], folderToAdd: any, parentId: number) => {
        //     for (const folder of folders) {
        //         if (folder.id === parentId) {
        //             folder.files.push(folderToAdd);
        //             return true;
        //         }
        //         // Recursively search in the files array (which may contain nested folders)
        //         if (folder.files.length > 0) {
        //             const added = findAndAddToParentFolder(folder.files, folderToAdd, parentId);
        //             if (added) return true;
        //         }
        //     }
        //     return false; // If the parent folder is not found
        // };

        // Fetch folders first
        // axios
        //     .get(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`)
        //     .then((folderResponse: any) => {
        //         const rootFolders: any[] = [];

        //         const folders = folderResponse.data.map((folder: any) => ({
        //             id: folder.folder_id,
        //             name: folder.folder_name,
        //             type: 'folder',
        //             folder: folder.parent_id ? folderResponse.data.filter((f: any) => f.folder_id === folder.parent_id)[0]?.folder_name : "All Documents",
        //             parent_id: folder.folder_id !== folder.parent_id ? folder.parent_id : null,
        //             s3_link: folder.s3_link || '',
        //             create_date: new Date(folder.create_date || Date.now()),
        //             doc_name: '',
        //             files: [],
        //         }));

        //         // console.log("folders:", currentUser?.first_name);

        //         // Add folders to their respective parent folders or root if no parent
        //         folders.forEach((folder) => {
        //             if (!folder.parent_id) {
        //                 rootFolders.push(folder); // Add to root if no parent
        //             } else {
        //                 findAndAddToParentFolder(rootFolders, folder, folder.parent_id);
        //             }
        //         });

        // Fetch files after folders
        axios
            .get(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`)
            .then((fileResponse: any) => {
                const files = fileResponse.data.map((file: any) => ({
                    id: file.mv_document_id,
                    name: file.file_name,
                    description: file.description,
                    doc_name: file.title,
                    folder: file.folder || null,
                    uploaded_by: file.uploaded_by || '',
                    parent_id: file.folder_id || null,
                    s3_link: file.s3_link,
                    in_elastic: file.in_elastic || false,
                    create_date: new Date(file.uploaded_on),
                    type: file.type || 'file',
                    is_genai_available: file.is_genai_available,
                    translation_required: file.translation_required || false,
                    mini_summary: file.mini_summary || '',
                }));

                // Separate files with null folders from files with non-null folders
                // const filesWithFolder = files.filter(file => file.folder !== null);
                const filesWithoutFolder = files.filter(file => file.folder === null);

                // Assign files to their respective folders
                // filesWithFolder.forEach((file) => {
                //     const targetFolder = folders.find(folder => folder.id === file.parent_id);
                //     if (targetFolder) {
                //         targetFolder.files.push(file);
                //     }
                // });

                // Sort the files within each folder
                // const sortFolderContents = (folder: any) => {
                //     folder.files.sort((a: any, b: any) => b.create_date.getTime() - a.create_date.getTime());
                //     folder.files.forEach((nestedFolder: any) => {
                //         if (nestedFolder.type === 'folder') sortFolderContents(nestedFolder);
                //     });
                // };
                // rootFolders.forEach(sortFolderContents);

                // Merge files without folders with the folder list
                const finalFiles = [
                    ...filesWithoutFolder.map(file => ({
                        id: file.id,
                        name: file.name,
                        type: file.type,
                        description: file.description,
                        doc_name: file.doc_name,
                        folder: 'All Documents',
                        uploaded_by: file.uploaded_by,
                        parent_id: null,
                        s3_link: file.s3_link,
                        create_date: file.create_date,
                        is_genai_available: file.is_genai_available,
                        in_elastic: file.in_elastic,
                        translation_required: file.translation_required,
                        mini_summary: file.mini_summary,
                    })),
                ].sort((a, b) => b.create_date.getTime() - a.create_date.getTime());

                // Update the state
                setUploadedFiles(finalFiles);
                setFilteredFiles(finalFiles);
                setIsLoading(false);
            })
            .catch(fileError => {
                console.error('Error fetching files:', fileError);
                setIsLoading(false);
            });
        // })
        // .catch(folderError => {
        //     console.error('Error fetching folders:', folderError);
        //     setIsLoading(false);
        // });
    }, [currentUser?.id, currentUser?.organization]);

    const fetchCompareRequests = useCallback(() => {
        axios.get(`/reg/v1/document/diff/${currentUser?.organization}`)
            .then(response => {
                setDiffFiles(response.data);
            }).catch(error => {
                console.error('Failed to fetch compare requests:', error);
            });
    }, [currentUser?.organization]);

    useEffect(() => {
        fetchCompareRequests();
    }, [fetchCompareRequests]);

    useEffect(() => {
        if (message === '' && !prevMessage.includes("Failed")) {
            fetchFiles();
        }
    }, [fetchFiles, message, prevMessage]);

    const handleSelectionChange = (fileId: number, isChecked: boolean) => {
        // console.log(fileId, isChecked);
        if (isChecked) {
            setSelectedFiles(prev => [...prev, fileId]);
        } else {
            setSelectedFiles(prev => prev.filter(f => f !== fileId));
        }
        // console.log(selectedFiles);
    };

    // const compareOnClose = () => {
    //     setCompareIsOpen(false);
    // }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!isUploaded) {
            setMessage('');
        }
    }, [isUploaded]);

    useEffect(() => {
        const searchTerm = (searchInput || '').trim().toLowerCase();

        console.log("searchTerm:", searchTerm);

        const filterFiles = (files: any[]) => {
            return files.filter((file: any) =>
                (file.doc_name && file.doc_name.toLowerCase().includes(searchTerm)) ||
                file.name.toLowerCase().includes(searchTerm) ||
                (file.uploaded_by && file.uploaded_by.toLowerCase().includes(searchTerm)) ||
                (file.description && file.description.toLowerCase().includes(searchTerm))
            );
        };

        if (!searchTerm) {
            setFilteredFiles(uploadedFiles);
            // } else if (selectedFolder && Object.values(selectedFolder)[0]) {
            //     const selected = uploadedFiles.find(file => file.name === Object.keys(selectedFolder)[0] && file.type === 'folder');
            //     if (selected) {
            //         const filtered = filterFiles(selected.files);

            //         const updatedFiles = uploadedFiles.map(file =>
            //             file.name === Object.keys(selectedFolder)[0] && file.type === 'folder'
            //                 ? { ...file, files: filtered }
            //                 : file
            //         );
            //         setFilteredFiles(updatedFiles);
            //     }
        } else {
            const filtered = filterFiles(uploadedFiles);
            setFilteredFiles(filtered);
        }
    }, [searchInput, uploadedFiles, selectedFolder]);


    const handleFilterChange = (key: string, value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value
        }));
    }

    const handleApplyFilters = (files) => {
        // Helper function to filter files based on filter criteria
        const filterFiles = (files) => {
            return files.filter((file: any) => {
                const fileDate = new Date(file.create_date).getTime();
                const startDate = filters.startDate ? new Date(filters.startDate).getTime() : null;
                const endDate = filters.endDate ? new Date(filters.endDate + "T23:59:59").getTime() : null;

                return (!filters.fileName || file.name.includes(filters.fileName)) &&
                    (!startDate || fileDate >= startDate) &&
                    (!endDate || fileDate <= endDate);
            });
        };

        // if (selectedFolder && Object.values(selectedFolder)[0]) {
        //     // Find the selected folder and filter its files
        //     const selected = files.find(file => file.name === Object.keys(selectedFolder)[0] && file.type === 'folder');
        //     if (selected) {
        //         const filtered = filterFiles(selected.files);

        //         // Update filtered files by replacing the files in the selected folder
        //         const updatedFiles = uploadedFiles.map(file =>
        //             file.name === Object.keys(selectedFolder)[0] && file.type === 'folder' ? { ...file, files: filtered } : file
        //         );
        //         setFilteredFiles(updatedFiles);
        //     }
        // } else {
        // Filter files in the root folder
        const filtered = filterFiles(files);
        setFilteredFiles(filtered);
        // }
    };

    useEffect(() => {
        setCurrentPage(1);
        setSortConfig({ key: 'create_date', direction: 'desc' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPages, selectedFolder, filters]);

    const handleResetFilters = () => {
        setFilters({
            fileName: '',
            startDate: '',
            endDate: ''
        });
        setFilteredFiles(uploadedFiles);
    };

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isUploaded) {
            timer = setTimeout(() => {
                setIsUploaded(false);
            }, 15000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isUploaded]);

    useEffect(() => {

        const getSortKey = (file: any) => {
            if (file.type === 'folder' && (sortConfig.key === 'doc_name' || sortConfig.key === 'name')) {
                return sortConfig.key === 'doc_name' ? 'name' : 'doc_name';
            }
            return sortConfig.key;
        };

        const sortFiles = (files: any[]) => {
            return [...files].sort((a, b) => {
                const aKey = getSortKey(a);
                const bKey = getSortKey(b);

                if (a[aKey] < b[bKey]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[aKey] > b[bKey]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        };

        // const updatedFiles = filteredFiles.map((file) => {
        //     if (selectedFolder && file.type === 'folder' && file.name === Object.keys(selectedFolder)[0]) {
        //         return { ...file, files: sortFiles(file.files) }; // Sort files within the selected folder
        //     }
        //     return file; // Other files remain unchanged
        // });

        // Sort root-level files if no folder is selected

        const sortedRootFiles = sortFiles(filteredFiles);

        setFilteredFiles(sortedRootFiles);
        handleApplyFilters(sortedRootFiles);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);



    const handleDelete = (document: {
        id: number,
        type: string,
        folder: string,
        name: string,
        description: string,
        s3_link: string,
        create_date: Date,
        doc_name: string,
        is_genai_available: number,
        in_elastic: Boolean,
        translation_required: Boolean,
        files: {
            id: number,
            type: string,
            parent_id: number,
            name: string,
            description: string,
            s3_link: string,
            create_date: Date,
            doc_name: string
        }[]
    } | null) => {
        if (document === null) return;

        if (document?.type === 'folder') {
            deleteFolder(document.name, document.id);
            return;
        }

        axios.delete(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, { data: { mv_document_id: document.id } })
            .then(response => {
                fetchFiles();
                // setDeleteConfirmation(false);
                setDocumentToDelete(null);
                setDeleteSuccess(true); // Set deleteSuccess to true when the deletion is successful
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const handleDiffDelete = (diff: any | null) => {
        axios.patch(`/reg/v1/document/diff/${currentUser?.organization}`, { diff_id: diff['diff_id'] })
            .then(response => {
                fetchCompareRequests();
                setDiffToDelete(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (deleteSuccess) {
            timer = setTimeout(() => {
                setDeleteSuccess(false);
            }, 15000); // 15 seconds
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [deleteSuccess]);

    const compareDocuments = async () => {
        if (selectedFiles.length === 2) {
            setShowAlert(false);
            const findFileRecursively = (files: any[], fileId: number): any => {
                for (const file of files) {
                    if (file.id === fileId && file.type === 'file') {
                        return file;
                    }
                    if (file.type === 'folder' && file.files.length > 0) {
                        const found = findFileRecursively(file.files, fileId);
                        if (found) {
                            return found;
                        }
                    }
                }
                return null;
            };

            const file1 = findFileRecursively(uploadedFiles, selectedFiles[0]);
            const file2 = findFileRecursively(uploadedFiles, selectedFiles[1]);

            const response = await axios.post(`/reg/v1/document/diff/${currentUser?.organization}`, {
                doc_id_1: selectedFiles[0],
                doc_id_2: selectedFiles[1]
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                //console.log(`/doc-diff/${file1?.doc_name}/${file2?.doc_name}`);
                navigate(`/doc-comparison/${file1?.doc_name}/${file2?.doc_name}`,
                    {
                        state: {
                            filename1: file1?.name,
                            filename2: file2?.name,
                            isCompleted: response.data[0].isCompleted,
                            url: response.data[0].isCompleted ? response.data[0].url : '',

                        }
                    }
                );
                fetchCompareRequests();
            }
        } else {
            setShowAlert(true);
            // return(
            //     <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
            //         <i className="bi bi-info fs-2 me-2"></i>
            //         Please select exactly two files to compare.
            //     </div>
            // )
            // alert("Please select exactly two files to compare.");
        }
    };

    const updateDocument = async (documentId: number, newName: string, newDescription: string, documentType: string, parentId: number | null) => {
        try {
            if (documentType === 'folder') {
                const response = await axios.patch(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`, {
                    folder_id: documentId,
                    folder_name: newName,
                    parent_id: parentId
                });

                if (response.status !== 200) {
                    throw new Error(response.data.message);
                }

                return response.data;
            } else {
                const response = await axios.patch(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, {
                    mv_document_id: documentId,
                    file_name: newName,
                    title: newDescription,
                    folder_id: parentId
                });

                if (response.status !== 200) {
                    throw new Error(response.data.message);
                }


                return response.data;
            }
        } catch (error) {
            console.error('Failed to update document:', error);
        }

    };

    const findFolderRecursively = (files: any[], folder: { [key: string]: number | null }): any => {
        for (const file of files) {
            if (file.type === 'folder') {
                if (file.name === Object.keys(folder)[0] && file.id === Object.values(folder)[0]) {
                    return file;
                }
                const found = findFolderRecursively(file.files || [], folder);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };


    const toggleFolder = (folder: { [key: string]: number | null }, isReverse: boolean) => {
        const folderName = folder ? Object.keys(folder)[0] : "All Documents";
        if (isReverse) {
            if (folderName === "All Documents") {
                setSelectedFolder({ "All Documents": null });
                setPrevFolder(null);
                return;
            }
            const selected = findFolderRecursively(uploadedFiles, folder);
            if (selected) {
                setSelectedFolder(prevFolder ? prevFolder : { "All Documents": null });
                setPrevFolder(selected.folder ? { [selected.folder]: selected.parent_id } : { "All Documents": null });
            } else {
                console.warn(`Folder ${folderName} not found during reverse navigation.`);
            }
            return;
        }
        // Find the selected folder in uploadedFiles
        const selected = findFolderRecursively(uploadedFiles, folder);
        setPrevFolder(selected.folder ? { [selected.folder]: selected.parent_id } : { "All Documents": null });

        // Handle "All Documents" special case
        if (folderName === "All Documents") {
            setSelectedFolder({ "All Documents": null });
            if (prevFolder && Object.keys(prevFolder)[0] === "All Documents") {
                setPrevFolder(null);
            }
        } else {
            setSelectedFolder(folder);
        }
    };

    const deleteFolder = (folderName: string, folderId: number) => {
        axios.delete(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`, { data: { folder_id: folderId } })
            .then(response => {
                fetchFiles();
                setModalMessage(`Folder deleted successfully! \n ${folderName}`);
                setShowCreateSuccessModal(true);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage(`Failed to delete folder: ${folderName} \n\n Reason: ${error.response.data[0].message}`);
                setShowCreateSuccessModal(true);
            });
    };

    const handleMoveFolder = (folderID: number | null, selectedFile: any) => {
        if (folderID === 0 || folderID === selectedFile.id) {
            folderID = null;

        }
        if (selectedFile.type === 'folder') {
            if (folderID === selectedFile.parent_id) {
                setModalMessage(`Folder already in the selected folder: ${selectedFile.name}`);
                setShowMoveSuccessModal(true);
                return;
            }
            else if (folderID === selectedFile.id) {
                setModalMessage(`Cannot move folder into itself: ${selectedFile.name}`);
                setShowMoveSuccessModal(true);
                return;
            }
            axios.patch(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`, {
                folder_id: selectedFile.id,
                parent_id: folderID,
                folder_name: selectedFile.name
            }).then(response => {
                fetchFiles();
                setShowMoveModal(false);
                setModalMessage(`Folder moved successfully! \n ${selectedFile.name}`);
                setShowMoveSuccessModal(true);
            }
            ).catch(error => {
                console.error('There was an error!', error);
                setModalMessage(`Failed to move folder: ${selectedFile.name} \n\n Reason: ${error.response.data[0].message}`);
                setShowMoveSuccessModal(true);
            });
        } else {
            axios.patch(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, {
                mv_document_id: selectedFile.id,
                folder_id: folderID,
                file_name: selectedFile.name,
                title: selectedFile.doc_name
            }).then(response => {
                fetchFiles();
                setShowMoveModal(false);
                setModalMessage(`File moved successfully! \n ${selectedFile.name}`);
                setShowMoveSuccessModal(true);
            }
            ).catch(error => {
                console.error('There was an error!', error);
                setModalMessage(`Failed to move file: ${selectedFile.name} \n\n Reason: ${error.response.data[0].message}`);
                setShowMoveSuccessModal(true);
            });
        }
    };

    useEffect(() => {
        if (selectedFolder) {
            if (Object.keys(selectedFolder)[0] !== "All Documents") {
                const selected = findFolderRecursively(uploadedFiles, selectedFolder);
                if (selected) {
                    setSelectedFolderLink(selected.s3_link);
                }
            }
            else {
                setSelectedFolderLink(orgS3Path);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFolder, orgS3Path, uploadedFiles]);



    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='folder-up' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                Lexim Cabinet
                            </div>
                        </h1>
                    </div>
                </div>
                <div className='card card-custom mb-5'>
                    <div className='card-header card-header-stretch'>
                        <ul
                            className='nav nav-stretch nav-line-tabs
                            nav-line-tabs-2x
                            border-transparent
                            flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': activeTab === 'uploadDocuments' })}
                                    onClick={() => setActiveTab('uploadDocuments')}
                                    role='tab'
                                >
                                    Upload Documents
                                </h5>
                            </li>
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': activeTab === 'compareDocuments' })}
                                    onClick={() => setActiveTab('compareDocuments')}
                                    role='tab'
                                >
                                    {/* <KTIcon iconName='information' className='fs-5 me-1 text-primary' />  */}
                                    Compare Documents
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: activeTab === 'uploadDocuments' })}>
                                <UploadSuccessModal isOpen={isUploaded} onRequestClose={() => { setIsUploaded(false); setPrevMessage(message); setMessage(''); }} message={message} />
                                {prevFolder && (
                                    <div className="ms-5 mt-n2 pb-2"
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '30%' }}
                                        onClick={() => toggleFolder(prevFolder, true)}
                                    >
                                        <div style={{
                                            marginRight: '20px',
                                            width: '250px',
                                            color: '#0d6efd',
                                            textDecoration: 'none',
                                            fontSize: '1.3em',
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        >
                                            <KTIcon iconName='arrow-left' className='fs-2 me-2' />
                                            {prevFolder && Object.keys(prevFolder)[0]}
                                        </div>
                                    </div>
                                )}
                                <MyDocumentsToolbar
                                    isLoading={isLoading}
                                    handleOpenModal={handleOpenModal}
                                    isModalOpen={isModalOpen}
                                    handleCloseModal={handleCloseModal}
                                    setIsUploaded={setIsUploaded}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    filters={filters}
                                    handleFilterChange={handleFilterChange}
                                    handleApplyFilters={handleApplyFilters}
                                    handleResetFilters={handleResetFilters}
                                    setMessage={setMessage}
                                    orgS3Path={orgS3Path}
                                    currentUser={currentUser}
                                    selectedFolder={selectedFolder}
                                    fetchFiles={fetchFiles}
                                    setModalMessage={setModalMessage}
                                    setShowCreateSuccessModal={setShowCreateSuccessModal}
                                    selectedFolderLink={selectedFolderLink || orgS3Path}
                                />
                                {showAlert &&
                                    <div className={`alert alert-danger alert-dismissible fade show mt-3 d-flex justify-content-between`} role="alert">
                                        Please select exactly two (2) files to compare.
                                        <div className='justify-content-end' style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-x-circle-fill fs-2 ms-2 text-end" onClick={() => setShowAlert(false)}></i>
                                        </div>
                                    </div>
                                }
                                {isLoading ? <HourGlass /> :
                                    <div>
                                        <MyDocumentsTable
                                            filteredFiles={filteredFiles}
                                            selectedFiles={selectedFiles}
                                            sortConfig={sortConfig}
                                            handleHeaderClick={handleHeaderClick}
                                            navigate={navigate}
                                            editingFile={editingFile}
                                            setEditingFile={setEditingFile}
                                            name={name}
                                            description={description}
                                            updateDocument={updateDocument}
                                            compareDocuments={compareDocuments}
                                            fetchFiles={fetchFiles}
                                            setDeleteConfirmation={setDeleteConfirmation}
                                            setDocumentToDelete={setDocumentToDelete}
                                            handleSelectionChange={handleSelectionChange}
                                            setName={setName}
                                            setDescription={setDescription}
                                            setFilteredFiles={setFilteredFiles}
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            toggleFolder={toggleFolder}
                                            selectedFolder={selectedFolder}
                                            setTotalPages={setTotalPages}
                                            itemsPerPage={PAGINATION_PAGES_COUNT}
                                            prevFolder={prevFolder}
                                            setShowMoveModal={setShowMoveModal}
                                            setMoveFile={setMoveFile}
                                            handleMoveFolder={handleMoveFolder}
                                            setShowCreateSuccessModal={setShowCreateSuccessModal}
                                        />
                                        {totalPages > 1 && (
                                            <div className="mb-n6">
                                                <PaginationWrapper
                                                    totalPages={totalPages}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            </div>
                                        )}
                                        <DeleteConfirmationModal
                                            isOpen={deleteConfirmation}
                                            onRequestClose={() => {
                                                setDeleteConfirmation(false);
                                                setMessage('');
                                                setDocumentToDelete(null);
                                            }}
                                            onDelete={(document: any) => {
                                                handleDelete(document);
                                                setDeleteConfirmation(false);
                                            }}
                                            documentToDelete={documentToDelete}
                                        />
                                        <MoveFolderModal
                                            showModal={showMoveModal}
                                            setShowModal={setShowMoveModal}
                                            moveFile={moveFile}
                                            handleMoveFolder={handleMoveFolder}
                                            isLoading={isLoading}
                                        />
                                        {(showCreateSuccessModal || showMoveSuccessModal) && (
                                            <FolderSuccessModal
                                                showToast={showCreateSuccessModal || showMoveSuccessModal}
                                                onClose={() => {
                                                    setShowCreateSuccessModal(false);
                                                    setShowMoveSuccessModal(false);
                                                }}
                                                message={modalMessage}
                                            />
                                        )}
                                    </div>
                                }
                                {/* {compareIsOpen &&
                                    <CompareDocumentModal compareIsOpen={compareIsOpen} compareOnClose={compareOnClose} isCompleted={isCompleted} title1={title1} filename1={filename1} title2={title2} filename2={filename2} s3_link={url} setActiveTab={setActiveTab} />
                                } */}
                            </div>
                            <div className={clsx('tab-pane', { active: activeTab === 'compareDocuments' })}>
                                <DocDiffTable
                                    diffFiles={diffFiles.sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime())}
                                    uploadedFiles={uploadedFiles}
                                    setDiffDeleteConfirmation={setDiffDeleteConfirmation}
                                    setDiffToDelete={setDiffToDelete}
                                    isLoading={isLoading}
                                />
                                <DeleteConfirmationModal
                                    isOpen={diffDeleteConfirmation}
                                    onRequestClose={() => {
                                        setDiffDeleteConfirmation(false);
                                        setMessage('');
                                        setDiffToDelete(null);
                                    }}
                                    onDelete={(document) => {
                                        handleDiffDelete(document);
                                        setDiffDeleteConfirmation(false);
                                    }}
                                    documentToDelete={diffFiles.find((diff: any) => diff.diff_id === diffToDelete)}
                                    isDocDiff={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyDocuments;
// MyDocumentsTableHeader.tsx
import React from 'react';

const MyDocumentsTableHeader = ({ sortConfig, handleHeaderClick, compareDocuments, selectedFiles }) => {
    return (
        <thead className="text-start text-muted fw-bolder fs-7 gs-0">
            <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                <th className="text-start mt-5" style={{ whiteSpace: 'nowrap', width: '12%', marginRight: '3px' }}>
                    <button className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            compareDocuments();
                        }}
                        disabled={selectedFiles.length !== 2}
                    >
                        <i className="bi bi-plus-slash-minus fs-4"></i>
                        Compare
                    </button>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '25%', cursor: 'pointer' }} onClick={() => handleHeaderClick('doc_name')}>
                    Document Title
                    <span className={`sorting-icon ${sortConfig.key === 'doc_name' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'doc_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'doc_name' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '25%', cursor: 'pointer' }} onClick={() => handleHeaderClick('name')}>
                    File Name
                    <span className={`sorting-icon ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'name' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '12%', cursor: 'pointer' }} onClick={() => handleHeaderClick('uploaded_by')}>
                    Uploaded By
                    <span className={`sorting-icon ${sortConfig.key === 'uploaded_by' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'uploaded_by' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'uploaded_by' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                {/* <th style={{ whiteSpace: 'nowrap', width: '30%', cursor: 'pointer' }} onClick={() => handleHeaderClick('name')}>
                    Mini Summary
                    <span className={`sorting-icon ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'name' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th> */}
                <th style={{ whiteSpace: 'nowrap', width: '11%', textAlign: 'right', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                    Upload Date
                    <span className={`sorting-icon ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'create_date' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th className="text-end" style={{ whiteSpace: 'nowrap', width: '15%' }}></th>
                {/* <th className="text-end" style={{ whiteSpace: 'nowrap', width: '7%' }}></th> */}
            </tr>
        </thead>
    );
};

export default MyDocumentsTableHeader;
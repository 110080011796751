import React, { useState } from 'react';

const MultiSelectDropdown = ({
    label,
    options,
    selectedValues,
    onChange,
    isOpen,
    onToggle
}) => {
    const handleOptionToggle = (option) => {
        const newSelectedValues = selectedValues.includes(option)
            ? selectedValues.filter(val => val !== option)
            : [...selectedValues, option];
        onChange(newSelectedValues);
    };

    return (
        <div className="position-relative">
            <div
                className="form-control form-control-solid d-flex justify-content-between align-items-center"
                onClick={onToggle}
                style={{ cursor: 'pointer' }}
            >
                <span>
                    {selectedValues.length === 0
                        ? `Select ${label}`
                        : `${selectedValues.length} ${label} selected`}
                </span>
                <span className="ms-2">
                    {isOpen ? '▲' : '▼'}
                </span>
            </div>
            {isOpen && (
                <div
                    className="position-absolute w-100 border rounded mt-1 bg-white shadow-sm"
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        zIndex: 1000
                    }}
                >
                    {options.map((option) => (
                        <div
                            key={option}
                            className="px-3 py-2 d-flex align-items-center"
                            style={{
                                cursor: 'pointer',
                                backgroundColor: selectedValues.includes(option)
                                    ? 'rgba(0,123,255,0.1)'
                                    : 'transparent'
                            }}
                            onClick={() => handleOptionToggle(option)}
                        >
                            <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedValues.includes(option)}
                                readOnly
                            />
                            <label className="form-check-label">{option}</label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const DocumentFilter = ({
    filters,
    setFilters,
    onApplyFilters,
    onResetFilters,
    filterConfig,
    interests,
    programs
}) => {
    const [openDropdown, setOpenDropdown] = useState(null);

    // Handle filter changes dynamically
    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    // Toggle dropdown
    const handleDropdownToggle = (dropdownId) => {
        setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
    };

    // Render a form element based on the field type
    const renderFilterField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <div className="mb-4">
                        <label className="form-label fw-bold">{field.label}</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            value={filters[field.id]}
                            placeholder={field.placeholder}
                            onChange={(e) =>
                                handleFilterChange(field.id, e.target.value)
                            }
                        />
                    </div>
                );
            case 'date':
                return (
                    <div>
                        {field.label !== '' &&
                            <label className="form-label fw-bold" htmlFor={field.id}>
                                {field.label}
                            </label>
                        }
                        <input
                            type="date"
                            className="form-control form-control-solid mb-3"
                            value={filters[field.id]}
                            onChange={(e) =>
                                handleFilterChange(field.id, e.target.value)
                            }
                        />
                    </div>
                );
            case 'multiselect-interests':
                return (
                    <div className="mb-4">
                        <label className="form-label fw-bold">{field.label}</label>
                        <MultiSelectDropdown
                            label="Interests"
                            options={interests.agencies}
                            selectedValues={filters[field.id] || []}
                            onChange={(values) => handleFilterChange(field.id, values)}
                            isOpen={openDropdown === field.id}
                            onToggle={() => handleDropdownToggle(field.id)}
                        />
                    </div>
                );
            case 'multiselect-programs':
                return (
                    <div className="mb-4">
                        <label className="form-label fw-bold">{field.label}</label>
                        <MultiSelectDropdown
                            label="Programs"
                            options={Object.keys(programs)}
                            selectedValues={filters[field.id] || []}
                            onChange={(values) => handleFilterChange(field.id, values)}
                            isOpen={openDropdown === field.id}
                            onToggle={() => handleDropdownToggle(field.id)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
            <div className="px-7 py-5">
                <div className="fs-5 text-dark fw-bolder">Filter Options</div>
            </div>
            <div className="separator border-gray-200"></div>
            <div className="px-7 py-5" style={{ maxHeight: '300px', overflow: 'auto' }}>
                {filterConfig.map((field, index) => (
                    <div key={field.id || index}>
                        {renderFilterField(field)}
                    </div>
                ))}
            </div>
            <div className="px-7 pb-7">
                <div className="d-flex justify-content-end">
                    <button
                        type="reset"
                        className="btn btn-sm btn-light btn-active-light-primary me-2"
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentFilter;
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserById, login, getUserInterests } from '../core/_requests'
//import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
//import { KTIcon } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { UPDATE_AVATAR, UPDATE_INTERESTS } from '../../../../_metronic/redux/account/types';
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong username format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [mouse, setMouse] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        if (!auth['is_registered']) {
          navigate('/setup-user/', { state: { email: values.email, password: values.password } });
        }
        else if (auth['msg'] !== "login successful") {
          setStatus(auth['msg'])
          setSubmitting(false)
          setLoading(false)
          return
        }
        else {
          auth.userid = auth['user']
          if (auth && auth['user']) {
            saveAuth(auth)
            const user = await getUserById(auth.userid)
            if (user) {
              // Fetch interests and addons in parallel
              // const [interests, addons] = await Promise.all([
              //   getUserInterests(auth.userid),
              //   getUserAddons(auth.userid)
              // ]);

              const interests = await getUserInterests(auth.userid);
              dispatch({ type: UPDATE_AVATAR, url: auth.profile_pic });
              dispatch({ type: UPDATE_INTERESTS, interests: interests });
              //localStorage.setItem('interests', JSON.stringify(interests));
              // console.log('Interests:', interests)
              // localStorage.setItem('addons', JSON.stringify(addons));
              // dispatch({ type: UPDATE_ADDONS, addons: addons });
              setCurrentUser(user)

            } else {
              console.error('Auth data is undefined')
              setStatus('The login details are incorrect')
              setSubmitting(false)
              setLoading(false)
              return
            }
          }
        }
      } catch (error) {
        console.error(error)
        // Set status based on Axios error response
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const axiosError = error as { response?: { status?: number, data?: any } }
          if (axiosError.response) {
            // console.log(axiosError.response.data.message)
            if (axiosError.response.status === 400) {
              setStatus(axiosError.response.data.message)
            } 
            // else if (axiosError.response.status === 403) {
            //   setStatus('Account not verified')
            // } else if (axiosError.response.data?.msg) {
            //   setStatus(axiosError.response.data.msg)
            // } 
            else {
              setStatus('An unexpected error occurred. Please try again.')
            }
          } else {
            setStatus('Network error. Please check your connection.')
          }
        } else {
          setStatus('Server in maintenance. Please try again later.')
        }
        saveAuth(undefined)
        // setStatus('The login details are incorrect ')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/* <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div> */}

      {/* 
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div>
         
      </div> */}
      {/* end::Login options */}

      {/* begin::Separator */}
      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className=''>
          <div className='text-info'>
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='fv-row mb-3 position-relative'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {/* <div className="input-group-append border-0"> */}
        <button
          type="button"
          // className="btn btn-light btn-lg border-0 position-absolute end-0 me-10"
          className="btn bg-transparent"
          style={{ position: 'absolute', top: '50%', right: '7px', transform: 'translateY(-20%)', borderStartStartRadius: 0, padding: '5px 10px', backgroundColor: mouse ? 'transparent' : '', borderColor: mouse ? 'transparent' : '' }}
          // style={{
          // top: '32px', right: '20px',
          //   top: '39px',
          //   left: '345px',
          //   height: 'fit-content',
          //   width: 'fit-content',
          //   padding: 1,
          //   paddingLeft: 5,
          //   paddingTop: 0.2,
          //   backgroundColor: hover ? 'transparent' : '',
          //   borderColor: hover ? 'transparent' : ''
          // }}
          onClick={() => {
            togglePasswordVisibility()
          }}
          onMouseEnter={() => setMouse(true)}
          onMouseLeave={() => setMouse(false)}
          onMouseDown={() => setMouse(true)}
          onMouseUp={() => setMouse(false)}
        >
          <i className={`bi ${showPassword ? "bi-eye-slash border-0" : "bi-eye border-0"}`}></i>
        </button>
        {/* </div> */}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

function CountryCompare() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const selectedRows = [
        { 'Classification for Medical Device': 'classification' },
        { 'Clinical Trials Medical Device': 'clinical_trials_medical_device' },
        { 'Fees for Medical Device': 'fees_medical_device' },
        { 'Global Post-Market Overview': 'global_postmarket_overview' },
        { 'Global Pre-Market Overview': 'global_premarket_overview' },
        { 'Import and Export': 'import_export' },
        { 'IVD as a Medical Device': 'ivd_as_medical_device' },
        { 'Market Clearance': 'market_clearance' },
        { 'Safety Medical Device': 'safety_medical_device' }
    ];
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [countryDropdownOpen, toggleCountryDropdown] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [countryUrls, setCountryUrls] = useState([]);
    const [fetchedData, setFetchedData] = useState({});

    const fetchGapAnalysisData = async (countryValue) => {
        try {
            const response = await axios.get(`/reg/v1/international/gap_analysis/${countryValue}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for ${countryValue}:`, error);
            return null; // Handle errors appropriately
        }
    };

    const handleChange = async (selectedOptions) => {
        const newCountries = selectedOptions.map(option => option.value);
        const previouslySelected = selectedCountries.map(option => option.value);

        // Countries that are newly added
        const addedCountries = newCountries.filter(value => !previouslySelected.includes(value));
        // Countries that were removed
        const removedCountries = previouslySelected.filter(value => !newCountries.includes(value));

        // Fetch data only for newly added countries
        const newDataPromises = addedCountries.map(async (country) => {
            const data = await fetchGapAnalysisData(country);
            return { [country]: data };
        });

        const newData = await Promise.all(newDataPromises);
        const newUrlsMap = newData.reduce((acc, curr) => ({ ...acc, ...curr }), {});

        // Remove data for removed countries
        const updatedUrlsMap = { ...countryUrls };
        removedCountries.forEach(country => {
            delete updatedUrlsMap[country];
        });

        // Merge the new data with the existing data
        setCountryUrls({ ...updatedUrlsMap, ...newUrlsMap });
        setSelectedCountries(selectedOptions);
    };

    const fetchCountryData = async () => {
        setIsLoading(true);
        axios.get(`/reg/v1/international/gap_analysis/countries`)
            .then((response) => {
                const countryMap = response.data.map((country) => ({ value: country.agency_id, label: `${country.country.trim()} (${country.short_name.trim()})` }));
                setCountryOptions(countryMap);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch country data', error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        fetchCountryData();

    }, []);

    useEffect(() => {
        console.log('country urls:', countryUrls);
    }, [countryUrls]);

    const getUrlForRowAndCountry = async (row, countryId) => {
        const rowKey = Object.values(row)[0];
        const countryData = countryUrls[countryId];

        if (countryData && countryData.length > 0) {
            try {
                const textResponse = await axios.get(countryData[0][rowKey]);
                const formattedText = textResponse.data;
                //console.log('textResponse:', textResponse);
                //console.log('formattedText:', formattedText[0].response);
                return formattedText[0].response; // Return the response data once the promise resolves
            } catch (error) {
                console.error('Error fetching data:', error);
                return null;
            }
        }

        return null; // Return null if not found
    };

    // const renderData = (data, level = 0) => {
    //     return Object.entries(data).map(([key, value], index) => {
    //         if (typeof value === 'string' && value.includes('..........')) {
    //             return (
    //                 <React.Fragment key={index}>
    //                     {key && (
    //                         <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
    //                             <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
    //                         </Row>
    //                     )}
    //                     {value && (
    //                         <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-7' : ''}`}>
    //                             <Col md={12} dangerouslySetInnerHTML={{ __html: generateTable(value) }}></Col>
    //                         </Row>
    //                     )}
    //                 </React.Fragment>
    //             );
    //         }
    //         else if (typeof value === 'object' && value !== null) {
    //             return (
    //                 <React.Fragment key={index}>
    //                     {key && (
    //                         <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
    //                             <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
    //                         </Row>
    //                     )}
    //                     {value && (
    //                         <Row className={`card-title fs-6  text-justify ${level > 0 ? 'ps-7' : ''}`}>
    //                             <Col md={12}>{renderData(value, level + 1)}</Col>
    //                         </Row>
    //                     )}
    //                 </React.Fragment>
    //             );
    //         } else {
    //             return (
    //                 <React.Fragment key={index}>
    //                     {key && (
    //                         <Row className={`p-1 card-title  fw-bold text-uppercase bg-light-primary text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
    //                             <Col md={12}>{level > 0 ? `\u23F5  ${key}` : key}</Col>
    //                         </Row>
    //                     )}
    //                     {value && (
    //                         <Row className={`card-title fs-6 text-justify ${level > 0 ? 'ps-7' : ''}`}>
    //                             <Col md={12}>

    //                                 <StyledMarkdown className="markdown" remarkPlugins={[gfm]} >{value.replace(/(\n)(?!\|)/g, '\n\n')}</StyledMarkdown>

    //                             </Col>
    //                         </Row>
    //                     )}
    //                 </React.Fragment>
    //             );
    //         }
    //     });
    // };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const newFetchedData = { ...fetchedData };

            for (const country of selectedCountries) {
                for (const row of selectedRows) {
                    const rowKey = Object.values(row)[0];
                    const countryId = country.value;
                    const dataKey = `${countryId}-${rowKey}`;

                    // If data is already fetched, don't fetch it again
                    if (!newFetchedData[dataKey]) {
                        const data = await getUrlForRowAndCountry(row, countryId);
                        newFetchedData[dataKey] = data;
                    }
                }
            }
            setIsLoading(false);

            setFetchedData(newFetchedData); // Set the fetched data once
        };

        // Avoid re-fetching data when no rows/countries are selected
        if (selectedCountries.length > 0 && selectedRows.length > 0) {
            fetchData();
        }
    }, [selectedCountries]);

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='parcel-tracking' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span>Country Compare</span>
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-column-fluid px-1 align-items-center justify-content-center mt-3">
                    <div className="d-flex flex-row align-items-center justify-content-start w-100 gap-4 px-10">
                        {/* Country Select */}
                        <div className="d-flex flex-column">
                            <div className="mb-4 dropdown">
                                <button
                                    className="btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle text-start form-control form-control-sm form-control-solid fw-bold"
                                    type="button"
                                    id="countryDropdown"
                                    onClick={() => toggleCountryDropdown(!countryDropdownOpen)}
                                    style={{
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        width: 'auto', // Ensures the button has auto width
                                    }}
                                >
                                    Select Countries
                                </button>
                                {countryDropdownOpen && (
                                    <ul
                                        className="dropdown-menu show mt-1"
                                        style={{
                                            maxWidth: 'none', // Allows dropdown to expand to fit content
                                            minWidth: '250px', // Sets a minimum width for consistency
                                            maxHeight: 'auto', // Allows dropdown to grow
                                            overflowY: 'auto', // Adds scroll when necessary
                                            borderRadius: '8px',
                                            borderColor: '#e5e5e5',
                                        }}
                                        aria-labelledby="countryDropdown"
                                    >
                                        {countryOptions.map((country, index) => (
                                            <li key={index} style={{ margin: '10px 0', width: '100%' }}>
                                                <input
                                                    className="form-check-input me-2 mb-2 ms-3"
                                                    type="checkbox"
                                                    id={`country-${index}`}
                                                    value={country.value}
                                                    checked={selectedCountries.some(
                                                        (selected) => selected.value === country.value
                                                    )}
                                                    onChange={(e) => {
                                                        const updatedSelection = e.target.checked
                                                            ? [...selectedCountries, country]
                                                            : selectedCountries.filter(
                                                                (selected) => selected.value !== country.value
                                                            );
                                                        // if (updatedSelection.length <= 5) {
                                                        handleChange(updatedSelection);
                                                        // }
                                                        // else {
                                                        //     setAlert({ message: 'You can only select up to 5 countries.', type: 'danger' });
                                                        // }
                                                    }}
                                                />
                                                <label
                                                    className="form-label fw-bold ms-2"
                                                    htmlFor={`country-${index}`}
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        display: 'inline-block', // Ensures label stays in the same line as checkbox
                                                        wordBreak: 'break-word', // Ensures text breaks if it's too long
                                                        whiteSpace: 'normal', // Allows text to wrap
                                                        maxWidth: 'calc(80%)', // Adjusts max width to prevent overflow
                                                    }}
                                                >
                                                    {country.label}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>
                        </div>

                        {/* Compare Button */}
                        {/* <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                            <button
                                className="btn btn-primary fw-bold"
                                onClick={() => console.log(selectedCountries, selectedRows)}
                                disabled={selectedCountries.length === 0 || selectedRows.length === 0}
                            >
                                Generate Results
                            </button>
                        </div> */}
                    </div>
                </div>

                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3 mx-11`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}
                {isLoading ? (
                    <HourGlass />
                ) : (
                    <div className='table-responsive px-5 mt-3 mb-10' style={{ overflowX: 'auto', width: '100%' }}>
                        <table
                            className="table table-striped table-hover table-rounded border gy-5 gs-7 dataTable no-footer"
                            style={{
                                '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                                '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                                tableLayout: 'fixed', // Fixed layout to prevent shrinking
                                minWidth: '100%',
                                width: 'max-content'
                            }}
                        >
                            {/* Table Head */}
                            <thead className="text-start text-muted fw-bolder align-middle fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    {/* First Column - Fixed 300px width */}
                                    <th
                                        style={{
                                            whiteSpace: 'nowrap',
                                            width: '200px',
                                            minWidth: '200px',
                                            maxWidth: '200px',
                                            paddingRight: '20px'
                                        }}
                                    >
                                        Topics
                                    </th>
                                    {/* Dynamically render columns for each selected country - Fixed 200px width */}
                                    {selectedCountries.length === 0 ? (
                                        <th className='text-dark fw-semibold fs-5' style={{ textAlign: 'left' }}>
                                            Countries
                                        </th>
                                    ) : selectedCountries.map((country, index) => (
                                        <th
                                            key={index}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textAlign: 'left',
                                                width: '300px',
                                                minWidth: '300px',
                                                maxWidth: '300px',
                                                paddingRight: '20px'
                                            }}
                                        >
                                            {country.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {selectedRows.length === 0 || selectedCountries.length === 0 ? (
                                    <tr>
                                        <td colSpan={selectedCountries.length + 2} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>
                                            Please select at least one country to get started.
                                        </td>
                                    </tr>
                                ) : (
                                    selectedRows.map((row, rowIndex) => (
                                        <tr key={rowIndex} className='align-items-center'>
                                            {/* First column - Row Title */}
                                            <td
                                                className='text-dark fw-semibold fs-6'
                                                style={{ textAlign: 'left', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                            >
                                                {Object.keys(row)[0]}
                                            </td>
                                            {/* Render data for each country in this row */}
                                            {selectedCountries.map((country, countryIndex) => (
                                                <td
                                                    key={`${rowIndex}-${countryIndex}`}
                                                    className="text-dark fw-semibold fs-6"
                                                    style={{ textAlign: 'left', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                >
                                                    {/* {typeof fetchedData[`${country.value}-${Object.values(row)[0]}`] === 'object' ? (
                                                        renderData(fetchedData[`${country.value}-${Object.values(row)[0]}`])
                                                    ) : ( */}
                                                    <ReactMarkdown>{fetchedData[`${country.value}-${Object.values(row)[0]}`] || 'N/A'}</ReactMarkdown>
                                                    {/* )} */}
                                                </td>
                                            ))}
                                        </tr>
                                    ))

                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CountryCompare;

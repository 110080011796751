import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {

    // const replacements = {
    //     '': '™',
    //     '': '"',
    //     '': '"',
    //     '®': '®',
    //     '©': '©',
    //     '': '—',
    //     '': '–',
    //     '': '•',
    //     '': '\'',
    //     '': '\'',
    //     '': '…',
    //     '': '¨',
    //     '': '‰',
    //     '': '‹',
    //     '': '›',
    //     '': '«',
    //     '': '»',
    //     '¿': '®',
    // }

    const sortingMap = {
        'Date of Event': 'date_of_event',
        'Manufacturer Name': 'manufacturer_g1_name',
        'Product Problems': 'product_problems',
        'Source Type': 'source_type',
        'Report Source Code': 'report_source_code',
        'Type of Report': 'type_of_report',
    }

    const parseYYYYMMDD = (dateStr) => {
        const year = dateStr.slice(0, 4);
        const month = dateStr.slice(4, 6) - 1; // Months are 0-indexed
        const day = dateStr.slice(6, 8);
        return new Date(Date.UTC(year, month, day));
      };
      

    return (

        <div className='table-responsive px-5 mb-6'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-mreport_numberle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>

                        <th style={{ cursor: 'pointer', width: '15%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Date of Event']);
                                }
                            }}
                        >
                            Date of Event
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Date of Event'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Date of Event'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th style={{ cursor: 'pointer', width: '25%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Manufacturer Name']);
                                }
                            }}
                        >
                            Manufacturer Name
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Manufacturer Name'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Manufacturer Name'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ cursor: 'pointer', width: '20%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('manufacturer_name');
                                }
                            }}
                        >
                            Company
                            <span className={`sorting-icon ${sortConfig.key === 'manufacturer_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'manufacturer_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className="text-nowrap" style={{ cursor: 'pointer', width: '20%' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Product Problems']);
                                }
                            }}
                        >
                            Product Problems
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Product Problems'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Product Problems'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-nowrap" style={{ cursor: 'pointer', width: '17%' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Source Type']);
                                }
                            }}
                        >
                            Source Type
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Source Type'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Source Type'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ cursor: 'pointer', width: '20%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Report Source Code']);
                                }
                            }}
                        >
                            Report Source Code
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Report Source Code'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Report Source Code'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ cursor: 'pointer', width: '15%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick(sortingMap['Type of Report']);
                                }
                            }}
                        >
                            Type of Report
                            <span className={`sorting-icon ${sortConfig.key === sortingMap['Type of Report'] ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === sortingMap['Type of Report'] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        {/* <th className={`text-end text-nowrap ${sortConfig.key !== 'date_received' ? 'pe-3' : ''}`} style={{ cursor: 'pointer', width: '14%', marginRight: '20px' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('date_received');
                                }
                            }}
                        >
                            Received Date
                            <span className={`sorting-icon ${sortConfig.key === 'date_received' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'date_received' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className='text-end' style={{ width: '11%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((event, index) => (
                                <tr className='align-items-center bg-hover-light-primary' 
                                onClick={() => 
                                    // navigate(`/device-intelligence/adverseevents/${event.report_number}`, {state: { searchTerm: searchTerm, currentPage: currentPage }
                                    window.open(`/device-intelligence/adverseevents/${event.mdr_report_key}`, '_blank')
                                    }>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {/* {event.brand_name ? Object.keys(replacements).reduce((str, key) => {
                                                return str.replace(new RegExp(key, 'g'), replacements[key]);
                                            }, event.brand_name) : 'Unknown Brand'} */}
                                            {   event.date_of_event 
                                                ? new Date(parseYYYYMMDD(event.date_of_event)).toLocaleDateString('en-US', { timeZone: 'UTC' }) 
                                                : 'N/A'
                                            }
                                            
                                        </a>
                                    </td>
                                    <td style={{
                                        verticalAlign: 'mreport_numberle',
                                        maxWidth: '150px',
                                        overflow: 'hreport_numberen',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }} className='text-dark fw-semibold fs-6 text-wrap' onClick={(e) => e.preventDefault()}>
                                        {event.manufacturer_g1_name}
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.manufacturer_name}
                                        </a>
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.product_problems ? event.product_problems.join(", "): "N/A"}
                                        </a>
                                    </td>
                                    <td className="text-dark text-wrap fw-semibold fs-6" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        {/* <Link to={`/device-intelligence/classification/${event.product_code}`} style={{ color: 'primary' }} className='align-items-center text-center' target="_blank" >{event.product_code}</Link> */}
                                        {event.source_type.join(", ")}
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6 text-center' onClick={(e) => e.preventDefault()}>
                                        {event.report_source_code}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.type_of_report.join(", ")}
                                        </a>
                                    </td>

                                    {/* <td className='text-end'>
                                        <a href='/device-intelligence/adverseevents' className={`text-dark fw-semibold d-block fs-6`} onClick={(e) => e.preventDefault()}>
                                            {new Date(event.date_received).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                        </a>
                                    </td> */}
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link
                                            to={`/device-intelligence/adverseevents/${event.mdr_report_key}`}
                                            state={{ searchTerm: searchTerm, currentPage: currentPage }}
                                            style={{ color: 'primary' }}
                                            target="_blank"
                                        >
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;
import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ isLoading, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {
    return (
        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('pma_number');
                                }
                            }}
                        >
                            PMA Number
                            <span className={`sorting-icon ${sortConfig.key === 'pma_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'pma_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th style={{ whiteSpace: 'nowrap', width: '30%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.device_name.keyword');
                                }
                            }}
                        >
                            Device Name
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.device_name.keyword' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.device_name.keyword' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('applicant');
                                }
                            }}
                        >
                            Company
                            <span className={`sorting-icon ${sortConfig.key === 'applicant' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'applicant' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-center' style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('openfda.device_class');
                                }
                            }}
                        >
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'openfda.device_class' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda.device_class' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-center' style={{ whiteSpace: 'nowrap', width: '10%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('advisory_committee');
                                }
                            }}
                        >
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'advisory_committee' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'advisory_committee' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-center' style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('product_code');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        
                        <th className={`text-end ${sortConfig.key !== 'decision_date' ? 'pe-3' : ''}`} style={{ whiteSpace: 'nowrap', width: '12%', cursor: 'pointer' }}
                            onClick={() => {
                                if ((searchTerm || '').trim() === '') {
                                    handleHeaderClick('decision_date');
                                }
                            }}
                        >
                            Decision Date
                            <span className={`sorting-icon ${sortConfig.key === 'decision_date' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'decision_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '10%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {isLoading ? (
                    <tbody>
                        <tr>
                            <td colSpan={8} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {currentDevices.length === 0 ? (
                            <tr>
                                <td colSpan={8} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                            </tr>
                        ) : (
                            currentDevices.map((device, index) => (
                                <tr className='align-items-center bg-hover-light-primary'
                                    onClick={() => { 
                                        // navigate(`/device-intelligence/pma/${device.pma_number}`, { state: { searchTerm: searchTerm, currentPage: currentPage } }) }
                                        window.open(`/device-intelligence/pma/${device._source.pma_number}`, '_blank');
                                    }}
                                >
                                    <td className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {device._source.pma_number}
                                    </td>

                                    <td className="ps-3">
                                        <a href='/device-intelligence/pma' className='text-dark text-wrap fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.openfda.device_name}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/pma' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.applicant}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/pma' className='text-dark fw-semibold d-block fs-6 text-center' onClick={(e) => e.preventDefault()}>
                                            {device._source.openfda.device_class}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/pma' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device._source.advisory_committee}
                                        </a>
                                    </td>
                                    <td className="text-dark text-wrap text-center fw-semibold ps-3 fs-6" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/classification/${device._source.product_code}`} style={{ color: 'primary' }} target="_blank" >{device._source.product_code}</Link>
                                    </td>
                                    
                                    <td className='text-end'>
                                        <a href='/device-intelligence/pma' className={`text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'decision_date' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(device._source.decision_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/pma/${device._source.pma_number}`} state={{ searchTerm: searchTerm, currentPage: currentPage }} target='_blank'>
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;
import { useQuery } from 'react-query'
import { OrganizationEditModalForm } from './OrganizationEditModalForm'
import { isNotEmpty, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getOrganizationById } from '../core/_requests'
import { OrganizationCreateModalForm } from './OrganizationCreateModalForm'

const OrganizationEditModalFormWrapper = ({ activeTab, handleClose }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: Organization,
    error,
  } = useQuery(
    `${QUERIES.ORGANIZATIONS_LIST}-Organization-${itemIdForUpdate}`,
    () => {
      return getOrganizationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return activeTab === 'Create'
      ? <OrganizationCreateModalForm isOrganizationLoading={isLoading} Organization={{ id: undefined }} handleClose={handleClose} />
      : <OrganizationEditModalForm isOrganizationLoading={isLoading} Organization={{ id: undefined }} />
  }

  if (!isLoading && !error && Organization) {
    return activeTab === 'Create'
      ? <OrganizationCreateModalForm isOrganizationLoading={isLoading} Organization={Organization} handleClose={handleClose} />
      : <OrganizationEditModalForm isOrganizationLoading={isLoading} Organization={Organization} />
  }

  return null
}

export { OrganizationEditModalFormWrapper }

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ViewSDKClient from './ViewSDKClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import { Spinner } from 'react-bootstrap';
function FullWindow({ documentId, isInternational = false }) {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isLoading, setIsLoading] = useState(true);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchAndDisplayPDF = async () => {
            try {
                // const response = await axios.get(`/reg/v1/document/viewpdfa/${documentId}`);
                let response;
                try {
                    if (!isInternational) {
                        response = await axios.get(`/reg/v1/document/viewpdfa/${documentId}`);
                    }
                    else {
                        response = await axios.get(`/reg/v1/international/viewpdfa/${documentId}`);
                    }
                } catch (error) {
                    throw error;
                }
                const presignedUrl = response.data.url;
                //console.log('presignedUrl:', presignedUrl);
                const viewSDKClient = new ViewSDKClient();
                viewSDKClient.ready().then(() => {
                    viewSDKClient.previewFileUsingUrl('pdf-div', presignedUrl, { embedMode: "FULL_WINDOW", showAnnotationTools: false, showThumbnails: false, showBookmarks: false, showDownloadPDF: true });
                });
                setAlert({ message: '', type: '' });
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if (error?.response?.status === 401 || error?.response?.status === 400) {
                    setAlert({ message: 'Document is not available', type: 'danger' });
                    // console.log('Document is not available');
                    // logout();
                    // navigate('/auth/login');
                } else {
                    setAlert({ message: 'Error fetching PDF', type: 'danger' });
                    // console.log('Error fetching PDF:', error);
                }

            }
        };

        fetchAndDisplayPDF();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout, navigate, documentId]);

    return (
        <div style={{ height: '90vh' }}>
            {isLoading && <Spinner animation="border" />}
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div id="pdf-div" className="full-window-div" style={{ width: '100%', height: '95%' }}>
                {/* Add CSS styles for full window display */}
            </div>
        </div>
    );
}

export default FullWindow;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios'; // Import axios
import { useAuth } from '../../modules/auth';
// import { Link } from 'react-router-dom';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import { useNavigate, useLocation } from 'react-router-dom';
// import clsx from 'clsx';
import PaginationWrapper from '../../components/PaginationWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
// import { MixedWidget11, MixedWidget10 } from '../../../_metronic/partials/widgets';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import DocumentHeader from '../Document/Components/DocumentHeader';
import DocumentToolbar from '../Document/Components/DocumentToolbar';
//import { MixedWidgetWarning } from '../Home/MixedWidgetWarning';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

// import Fuse from 'fuse.js';

const WarningLetters = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const moment = require('moment-timezone');
    const location = useLocation();
    const stateData = location.state || { agency: '', period_code: 0 };
    const [filteredDocuments, setFilteredDocuments] = useState(null);

    const [sortConfig, setSortConfig] = useState({ key: 'posted_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');

    const [filters, setFilters] = useState({
        document: '',
        program: '',
        startDate: stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '',
        endDate: '',
        response: false,
        closeout: false
    });

    // Add applied sort and filters state that will only change when Apply is clicked
    const [appliedFilters, setAppliedFilters] = useState({ ...filters });
    const [appliedSortConfig, setAppliedSortConfig] = useState({ ...sortConfig });


    // const [filterTracked, setFilterTracked] = useState(documentType ? true : false);
    //const [filterSubject, setFilterSubject] = useState(stateData.agency || '');
    const [searchInput, setSearchInput] = useState('');
    const { logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    
    const [currentPage, setCurrentPage] = useState(1);
    // const [trackedDocuments, setTrackedDocuments] = useState([]);
    const interests = useSelector(state => state.account.interests);
    // const limit = 18446744073709551615; // Set your desired limit here
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    // const [programs, setPrograms] = useState({});
    // const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    // const [data, setData] = useState([]);
    // const [isInsightsVisible, setIsInsightsVisible] = useState(false);
    const prevSearchInputRef = useRef();

    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        try {
            const requestBody = {
                limit: itemsPerPage,
                offset: (currentPage - 1) * itemsPerPage,
                filters: {
                    response_letters: appliedFilters.response,
                    close_out_letter: appliedFilters.closeout,
                    start_date: appliedFilters.startDate || '',
                    end_date: appliedFilters.endDate || ''
                },
                sort: appliedSortConfig ? { 
                    "sort_col": appliedSortConfig.key,
                    "sort_order": appliedSortConfig.direction } : {},
                search_string: searchInput || ''
            };
            console.log(requestBody)

            const response = await axios.post('/reg/v1/document/warning-letters/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response)
            const totalCount = response.data.total_documents || 0;
            setTotalPages(Math.ceil(totalCount / itemsPerPage));
            
            setFilteredDocuments(response.data.documents || []);
            setError(null);
        } catch (error) {
            if (error.response?.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                setFilteredDocuments([]);
                setError(error);
            }
        } finally {
            setLoading(false);
        }
    }, [itemsPerPage, currentPage, appliedSortConfig, appliedFilters, searchInput, logout, navigate]);



    const handleApplyFilters = useCallback(() => {
        // setCurrentPage(1);

        // fetchDocuments();

        setAppliedFilters({...filters})
    }, [filters]);

    // useEffect(() => {
    //     setCurrentPage(1);
    //     // fetchDocuments();
    // }, [filters]);

    useEffect(() => {
        prevSearchInputRef.current = searchInput;
    }, [searchInput]);

    useEffect(() => {
        // const delayDebounceFn = setTimeout(async () => {
        //     if ((searchInput || '').trim() !== '') {
        //         setLoading(true);
        //         try {
        //             const jsonData = JSON.stringify({ response_letter: filters.response, closeout_letter: filters.closeout, start_date: filters.startDate, end_date: filters.endDate });
        //             // Make a GET request to fetch documents data if no cache is found
        //             const response = await axios.post(`/reg/v1/search/search/warning-letter?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchInput}`,
        //                 { filter: jsonData },
        //                 {
        //                     headers: {
        //                         'Content-Type': 'application/json'
        //                     }
        //                 }

        //             );
        //             if (response.data) {
        //                 //setDocuments(response.data);
        //                 setFilteredDocuments(response.data);
        //                 setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
        //             }
        //         } catch (error) {
        //             if (error.response.status === 401) {
        //                 logout();
        //                 navigate('/auth/login');
        //             } else {
        //                 setError(error);
        //                 console.error('Error fetching search results:', error);
        //             }
        //         } finally {
        //             setLoading(false);
        //         }
        //     } else {
                //setDocuments([]);
                //setFilteredDocuments([]);
                fetchDocuments();
                // fetchTrackedDocuments();
        //     }
        //     //handleApplyFilters();
        // }, 0);

        // return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDocuments, interests, searchInput, itemsPerPage, currentPage]);

    // useEffect(() => {
    //     if (interests) {
    //         const newPrograms = {};
    //         interests.programs.forEach(program => {
    //             const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
    //             if (match && match.length > 2) {
    //                 const key = match[2].trim();
    //                 const value = match[1].trim();
    //                 newPrograms[key] = value;
    //             }
    //         });
    //         setPrograms(newPrograms);
    //     }
    // }, [interests]);


    const handleResetFilters = () => {
        // Clear filter state variables
        // setCurrentPage(1);
        setFilters({
            document: '',
            program: '',
            startDate: '',
            endDate: '',
            response: false,
            closeout: false
        });
        setAppliedFilters({
            document: '',
            program: '',
            startDate: '',
            endDate: '',
            response: false,
            closeout: false
        })
        // setFilterTracked(false);
        //setFilteredDocuments(documents);
    };

    const onApplySort = () => {
        // if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
        //     return;
        // }

        // let sortedDocuments = [...filteredDocuments];
        // if (sortConfig.direction === 'asc') {
        //     sortedDocuments.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        // } else if (sortConfig.direction === 'desc') {
        //     sortedDocuments.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        // }
        setAppliedSortConfig({key: sortConfig.key, direction: sortConfig.direction})
    };
    // Define onResetSort function
    const onResetSort = () => {
        // if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
        //     return;
        // }
        // let sortedDocuments = [...filteredDocuments];
        // sortedDocuments.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);

        setAppliedSortConfig({ key: 'posted_date', direction: 'desc'})
        setSortConfig({ key: 'posted_date', direction: 'desc' });
        setSortDirection("desc")
        // setFilteredDocuments(sortedDocuments);

    };
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        const trimmedSearchInput = (searchInput || '').trim();
        if (trimmedSearchInput === '' || trimmedSearchInput !== '') {
            setCurrentPage(1);
        }
    }, [searchInput]);

    const topRow = 'company_name';
    const secondaryRow = [['Subject', 'subject']];
    const thirdRow = [['Issuing Office', 'issuing_office']];
    const bottomRow = [['Posted Date', 'posted_date', formatDate], ['Letter Issue Date', 'letter_issue_date', formatDate]];
    const summaryRow = 'mini_summary';
    const links = ['warning_letter_url', 'id'];

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <DocumentHeader
                    docType={'warning-letters'}
                    // interests={interests}
                    // programs={programs}
                    filters={filters}
                />
                <DocumentToolbar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    docType={'warning-letters'}
                    handleApplyFilters={handleApplyFilters}
                    handleResetFilters={handleResetFilters}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    filters={filters}
                    setFilters={setFilters}
                />
                {error && <div className="error-message">{error.message}</div>}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}
                {loading ? <HourGlass /> : (
                    // <DocumentTable loading={loading} error={error} filteredDocuments={filteredDocuments} currentPage={currentPage}
                    //     DOCUMENTS_PER_PAGE={Math.max(itemsPerPage, 1)} formatDate={formatDate} formatDate2={formatDate2} searchInput={searchInput} docType={'warning-letters'}
                    // />
                    <DocumentListWrapper
                        filteredDocuments={filteredDocuments}
                        topRow={topRow}
                        secondaryRow={secondaryRow}
                        thirdRow={thirdRow}
                        bottomRow={bottomRow}
                        summaryRow={summaryRow}
                        links={links}
                        formatDate={formatDate}
                        formatDate2={formatDate2}
                        docType={'warning-letters'}
                        setAlert={setAlert}
                    />
                )}

                {/* {filteredDocuments.length === 0 && !loading && (
                    <div className="d-flex ms-10 mb-5">
                        <h3>No documents found with selected filters. Please try again with other filters.</h3>
                    </div>
                )} */}
                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">

                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                        <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }

            </div>

        </div>



    );
};

export default WarningLetters;
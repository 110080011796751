import axios from 'axios';

export const fetchDocuments = async (country, searchInput, sortConfig) => {
    try {

        // const params = {}; // Initialize empty params object

        // if (searchInput) {
        //     params.search_input = searchInput; // Only add if there's input
        // }

        const requestBody = {
            search_input: searchInput,
            sort: {
                sort_col: sortConfig.key,
                sort_order: sortConfig.direction
            }
        }

        console.log(requestBody)

        const response = await axios.post(`/reg/v1/document/international_doc/${country || 'all'}`, requestBody
            // params: { 'search_input': searchInput }
        // }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchTrackedDocuments = async (userId) => {
    try {
        const response = await axios.get(`/reg/v1/international/user/${userId}`);
        return response.data;
    } catch (error) {
        console.log('Failed to fetch tracked documents', error);
        throw error;
    }
};
// Function to format country names for display
export const formatCountry = (name) => {
    if (!name) return '';
    return name === 'eu' ? 'EU' : name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

// Function to format date from timestamp
export const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleDateString('en-US', {
        year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC'
    }) : null;
};

// Function to format date for international documents
export const formatDateInternational = (timestamp) => {
    if (!timestamp) return null;

    // Convert to number if it's a string
    let time = Number(timestamp);
    
    // Check if the timestamp is in milliseconds (length > 10 digits) and convert to seconds
    if (time > 10**10) {
        time /= 1000;
    }

    const date = new Date(time * 1000);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
 
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import { Tab, Tabs } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import FullWindow from './PDFViewer/FullWindow';
import clsx from 'clsx';

import HalfWindow from './HalfPDFViewer/HalfWindow';
import { QAInterface } from './GenAI/QAInterface';
import SummaryViewer from './GenAI/SummaryViewer';
import ImpactViewer from './GenAI/ImpactViewer';
import FAQViewer from './GenAI/FAQViewer';
import ActionItemsViewer from './GenAI/ActionItemsViewer';
// import DiscussionViewer from './GenAI/DiscussionViewer';
import TranslationViewer from './GenAI/TranslationViewer';
import BriefViewer from './GenAI/BriefViewer';

const MyDocumentDetails = () => {
    const location = useLocation();

    const uploadedFile = location.state?.uploadedFile;
    const selectedFolder = location.state?.selectedFolder;
    const prevFolder = location.state?.prevFolder;


    const id = uploadedFile?.id;
    const name = uploadedFile?.name;
    const doc_name = uploadedFile?.doc_name;
    const description = uploadedFile?.description;
    const create_date = uploadedFile?.create_date;
    // const s3_link = uploadedFile?.s3_link;
    const in_elastic = uploadedFile?.in_elastic;
    const translation_requested = uploadedFile?.translation_requested;
    const is_genai_available = uploadedFile?.is_genai_available;


    const [tab, setTab] = useState('Document');

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginLeft: '10px', width: '250px' }}>
                    <Link
                        to={{
                            pathname: "/apps/lexim-cabinet",
                        }}
                        state={{ selectedFolder: selectedFolder, prevFolder: prevFolder }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => (e.target as HTMLElement).style.color = '#0d6efd'}
                        onMouseLeave={(e) => (e.target as HTMLElement).style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' />
                        {'Cabinet Documents'}
                    </Link>
                </div>
            </div>
            <div className='card mt-4 mb-4' style={{ backgroundColor: 'rgba(204, 229, 255, 1)' }}>
                <div className='card-body align-items-center'>
                    <div className='position-relative'>
                        <div>
                            <h4>{name}</h4>
                            <h5 className="text-muted mt-4">{doc_name}</h5>
                            {description && <h5 className="text-muted">{description}</h5>}
                            {create_date && <h6 className="text-muted mt-4">Uploaded: {new Date(create_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</h6>}
                        </div>

                    </div>
                </div>
            </div>
            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Document' })}
                                onClick={() => setTab('Document')}
                                role='tab'
                            >
                                Document
                            </h5>
                        </li>
                        {is_genai_available===1 && (
                            <>
                                <li className='nav-item fs-4'>
                                    <h5
                                        className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Brief' })}
                                        onClick={() => setTab('Brief')}
                                        role='tab'
                                    >
                                        Lexim Brief
                                    </h5>
                                </li>
                                <li className='nav-item fs-4'>
                                    <h5
                                        className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Summary' })}
                                        onClick={() => setTab('Summary')}
                                        role='tab'
                                    >
                                        Lexim Summary
                                    </h5>
                                </li>
                                <li className='nav-item fs-4'>
                                    <h5
                                        className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Impact' })}
                                        onClick={() => setTab('Impact')}
                                        role='tab'
                                    >
                                        Impact
                                    </h5>
                                </li>
                                <li className='nav-item fs-4'>
                                    <h5
                                        className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'ActionItems' })}
                                        onClick={() => setTab('ActionItems')}
                                        role='tab'
                                    >
                                        Action Items
                                    </h5>
                                </li>
                                <li className='nav-item fs-4'>
                                    <h5
                                        className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'FAQ' })}
                                        onClick={() => setTab('FAQ')}
                                        role='tab'
                                    >
                                        FAQ
                                    </h5>
                                </li>
                            </>
                        )}
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'LeximQ&A' })}
                                onClick={() => setTab('LeximQ&A')}
                                role='tab'
                            >
                                Lexim Query
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Discussion' })}
                                onClick={() => setTab('Discussion')}
                                role='tab'
                            >
                                Discussion
                            </h5>
                        </li> */}
                        {translation_requested && (
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Translation' })}
                                    onClick={() => setTab('Translation')}
                                    role='tab'
                                >
                                    Translation
                                </h5>
                            </li>
                        )}
                    </ul>
                </div>
                <form className='form'>
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Document' })}>
                                {/* {console.log("Rendering FullWindow with id:", id)} */}
                                {id &&
                                    <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                                        <FullWindow documentId={id} />
                                    </div>
                                }
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'LeximQ&A' })}>
                                {id &&
                                    <div className={`d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 ${!in_elastic ? 'border-top' : ''} bg-white `}>
                                        {!in_elastic ? (
                                            <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                                                <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                                    Q&A for this document is still being generated. Please check back later!
                                                </div>
                                                <img src="/media/lexim-uploads/lexim-query-processing.png" alt="Background" style={{ maxWidth: '50%', maxHeight: '50%'}} />
                                            </div>
                                        ) : (
                                            <div className="row h-100">
                                                <div className="col-md-6">
                                                    <HalfWindow documentId={id} />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <QAInterface documentId={id} subtype='user' documentType='user' />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>


                            <div className={clsx('tab-pane', { active: tab === 'Summary' })}>
                                {id &&
                                    <div className="">
                                        <SummaryViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'Brief' })}>
                                {id &&
                                    <div className="">
                                        <BriefViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'Impact' })}>
                                {id &&
                                    <div className="">
                                        <ImpactViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'FAQ' })}>
                                {id &&
                                    <div className="">
                                        <FAQViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'ActionItems' })}>
                                {id &&
                                    <div className="">
                                        <ActionItemsViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            {/* <div className={clsx('tab-pane', { active: tab === 'Discussion' })}>
                                {console.log('id:', id)}
                                {id &&
                                    <div className="">
                                        <DiscussionViewer documentId={id} doc_source={2} txt_documentId={id} />
                                    </div>}
                            </div> */}
                            <div className={clsx('tab-pane', { active: tab === 'Translation' })}>
                                {id &&
                                    <div className="">
                                        <TranslationViewer iddocument={id} name={name} />
                                    </div>}
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'Training' })}>
                                <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                                    <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                        Video Training for this document is still being generated. Please check back later!
                                    </div>
                                    <img src="/media/lexim-uploads/lexim-query-processing.png" alt="Background" style={{ maxWidth: '50%', maxHeight: '50%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default MyDocumentDetails;

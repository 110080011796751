import { useEffect, useState } from 'react'
import { OrganizationEditModalHeader } from './OrganizationEditModalHeader'
import { OrganizationEditModalFormWrapper } from './OrganizationEditModalFormWrapper'
import { Modal, Button } from 'react-bootstrap';
import { useListView } from '../core/ListViewProvider';

const OrganizationEditModal = ({ activeTab }) => {
  const [showModal, setShowModal] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const { setItemIdForUpdate } = useListView()

  const handleClose = (message = '') => {
    if (message.includes('successfully')) {
      setShowModal(false);
    }
    if (message !== '') {
      setModalMessage(message);
      setShowSuccessModal(true);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <>
          {/* Modal */}
          <div
            className="modal fade show d-block"
            id="kt_modal_edit_organization"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <OrganizationEditModalHeader activeTab={activeTab} />
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-3">
                  <OrganizationEditModalFormWrapper activeTab={activeTab} handleClose={handleClose} />
                </div>
              </div>
            </div>
          </div>

          {/* Backdrop */}
          <div className="modal-backdrop fade show" onClick={() => handleClose('')}></div>
        </>
      )}
      <Modal show={showSuccessModal} onHide={() => { setShowSuccessModal(false); setItemIdForUpdate(undefined); }} dialogClassName="modal-dialog-centered">
        <Modal.Header closeButton />
        <Modal.Body className="d-flex justify-content-center fs-2">
          {modalMessage === 'Organization created successfully!' ? (
            <div>
              <i className="bi bi-check-circle-fill text-success fs-1 me-3"></i>
              {modalMessage}
            </div>
          ) : (
            <div>
              <i className="bi bi-x-circle text-danger fs-1 me-3"></i>
              {modalMessage}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => { setShowSuccessModal(false); setItemIdForUpdate(undefined); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { OrganizationEditModal };

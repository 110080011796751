// import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useAuth } from '../../../../../app/modules/auth'
import { bookmarksItems, adminItems, superAdminItems } from './MenuConfig'


// Helper function to sort items by title
const sortItemsByTitle = (items) => [...items].sort((a, b) => a.title.localeCompare(b.title));

// Helper function to render menu items
const renderMenuItems = (items) =>
  items.map((item, index) => (
    <MenuItem
      key={index}
      {...(item.icon ? { icon: item.icon } : { fontIcon: item.fontIcon })}
      to={item.to}
      title={item.title}
      hasArrow={item.hasArrow}
    />
  ));

export function MenuInner() {
  // const intl = useIntl()
  const { currentUser } = useAuth()

  // console.log('MenuInnerComponent rendered');
  return (
    <>
      {/* Home menu item (currently commented out) */}
      {/* <MenuItem title={intl.formatMessage({ id: 'Home' })} to='/home' /> */}

      {/* Bookmarks section - Displays user's saved items */}
      <MenuInnerWithSub title='Bookmarks' to='/myitems' menuPlacement='bottom-start' menuTrigger='click' fontIcon='bi-bookmarks-fill' hasArrow={true}>
        {renderMenuItems(bookmarksItems)}
      </MenuInnerWithSub>

      {/* Code of Regulations section (currently commented out) */}
      {/* <MenuInnerWithSub title='Code of Regulations' to='/cor' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} icon='add-folder'>
        {renderMenuItems(sortItemsByTitle(codeOfRegulationsItems))}
      </MenuInnerWithSub> */}

      {/* Legislation section (currently commented out) */}
      {/* <MenuInnerWithSub title='Legislation' to='/legislation' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-book-fill'>
        {renderMenuItems(sortItemsByTitle(legislationItems))}
      </MenuInnerWithSub> */}

      {/* Insights section - Displays reports and analytical data */}
      {/* <MenuInnerWithSub title='Insights' to='/reports' menuPlacement='bottom-start' menuTrigger='click' hasArrow={false} fontIcon='bi-bar-chart-line-fill'>
        {renderMenuItems(sortItemsByTitle(insightsItems))}
      </MenuInnerWithSub> */}

      <MenuItem
        key={0}
        {...{ fontIcon: 'bi-bar-chart-line-fill' }}
        to={'/insights'}
        title={'Insights'}
        hasArrow={false}
      />

      {/* Apps section (currently commented out) */}
      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-stack'>
        {renderMenuItems(sortItemsByTitle(appsItems))}
      </MenuInnerWithSub> */}

      {/* Resources section (currently commented out) */}
      {/* <MenuInnerWithSub title='Resources' to='/links' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-link-45deg'>
        {renderMenuItems(sortItemsByTitle(resourcesItems))}
      </MenuInnerWithSub> */}

      {/* Admin section - Visible only to users with role 2 or 3 */}
      {(currentUser?.role === 2 || currentUser?.role === 3) && (
        <MenuInnerWithSub to='/admin' title='Admin' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-person-fill-lock'>
          {renderMenuItems(sortItemsByTitle(adminItems))}
        </MenuInnerWithSub>
      )}

      {/* Super Admin section - Visible only to users with role 3 */}
      {(currentUser?.role === 3) && (
        <MenuInnerWithSub to='/super-admin' title='Lexim Admin' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-person-workspace'>
          {renderMenuItems(sortItemsByTitle(superAdminItems))}
        </MenuInnerWithSub>
      )}
    </>
  )
}

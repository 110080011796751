// import { TablesWidget } from './TablesWidget';
// import { TablesWidgetRecalls } from './TablesWidgetRecalls';
// import React from 'react';
// import Cookies from 'js-cookie';
// import { TablesWidgetWarning } from './TablesWidgetWarning';
// import { TablesWidget510 } from './TablesWidget510';
import { MixedWidgetGuidances } from './MixedWidgetGuidances';
import { MixedWidgetRegulatory } from './MixedWidgetRegulatory';
import { MixedWidgetWarning as MixedWidgetWarningComponent } from './MixedWidgetWarning';
// import { MixedWidgetAdverse } from './MixedWidgetAdverse';
// import { MixedWidget510 as MixedWidget510Component } from './MixedWidget510';
// import { TablesWidgetPMA } from './TablesWidgetPMA';
// import { MixedWidgetPMA } from './MixedWidgetPMA';
import { MixedWidgetRecalls } from './MixedWidgetRecalls';
import { MixedWidgetRegistration } from './MixedWidgetRegistration';



const CountAndTable = ({ days }) => {

    // const savedDocType = Cookies.get('selectedDocType') || 'Proposed Rule';
    // const [_doctype] = React.useState(savedDocType);
    // let proposedRuleCount, finalRuleCount, noticeCount, guidanceCount, recallsCount, eventsCount, lettersCount, five10kCount, pmaCount, registrationCount;

    
    // const tablewidgetComponents = {
    //     'Recalls': <TablesWidgetRecalls days={days} />,
    //     '510Ks': <TablesWidget510 days={days} />,
    //     // 'PMA': <TablesWidgetPMA days={days} />,
    //     'Warning Letters': <TablesWidgetWarning days={days} />,
    //     'Guidance': <TablesWidget doctype={doctype} days={days} />,
    //     'Notice': <TablesWidget doctype={doctype} days={days} />,
    //     'Rule': <TablesWidget doctype={doctype} days={days} />,
    //     'Proposed Rule': <TablesWidget doctype={doctype} days={days} />
    // };
    // const Tablecontent = tablewidgetComponents[doctype];

    // const [selectedCard, setSelectedCard] = useState<number>(getCardIndex(savedDocType));
    // switch (days) {
    //     case 7:
    //         proposedRuleCount = data?.proposed_rules_7;
    //         finalRuleCount = data?.final_rules_7;
    //         noticeCount = data?.notice_7;
    //         guidanceCount = data?.guidance_7;
    //         recallsCount = data?.recall_7;
    //         eventsCount = data?.adv_event_7;
    //         lettersCount = data?.warning_letter_7;
    //         five10kCount = data?.k_number_7;
    //         pmaCount = data?.pma_number_7;
    //         registrationCount = data?.reg_7;

    //         break;
    //     case 30:
    //         proposedRuleCount = data?.proposed_rules_30;
    //         finalRuleCount = data?.final_rules_30;
    //         noticeCount = data?.notice_30;
    //         guidanceCount = data?.guidance_30;
    //         recallsCount = data?.recall_30;
    //         eventsCount = data?.adv_event_30;
    //         lettersCount = data?.warning_letter_30;
    //         five10kCount = data?.k_number_30;
    //         pmaCount = data?.pma_number_30;
    //         registrationCount = data?.reg_30;

    //         break;
    //     case 90:
    //         proposedRuleCount = data?.proposed_rules_90;
    //         finalRuleCount = data?.final_rules_90;
    //         noticeCount = data?.notice_90;
    //         guidanceCount = data?.guidance_90;
    //         recallsCount = data?.recall_90;
    //         eventsCount = data?.adv_event_90;
    //         lettersCount = data?.warning_letter_90;
    //         five10kCount = data?.k_number_90;
    //         pmaCount = data?.pma_number_90;
    //         registrationCount = data?.reg_90;
    //         break;
    //     case 180:
    //         proposedRuleCount = data?.proposed_rules_180;
    //         finalRuleCount = data?.final_rules_180;
    //         noticeCount = data?.notice_180;
    //         guidanceCount = data?.guidance_180;
    //         recallsCount = data?.recall_180;
    //         eventsCount = data?.adv_event_180;
    //         lettersCount = data?.warning_letter_180;
    //         five10kCount = data?.k_number_180;
    //         pmaCount = data?.pma_number_180;
    //         registrationCount = data?.reg_180;
    //         break;
    //     case 365:
    //         proposedRuleCount = data?.proposed_rules_365;
    //         finalRuleCount = data?.final_rules_365;
    //         noticeCount = data?.notice_365;
    //         guidanceCount = data?.guidance_365;
    //         recallsCount = data?.recall_365;
    //         eventsCount = data?.adv_event_365;
    //         lettersCount = data?.warning_letter_365;
    //         five10kCount = data?.k_number_365;
    //         pmaCount = data?.pma_number_365;
    //         registrationCount = data?.reg_365;
    //         break;
    //     case 0:
    //         proposedRuleCount = data?.proposed_rules_all;
    //         finalRuleCount = data?.final_rules_all;
    //         noticeCount = data?.notice_all;
    //         guidanceCount = data?.guidance_all;
    //         recallsCount = data?.recall_all;
    //         eventsCount = data?.adv_event_all;
    //         lettersCount = data?.warning_all;
    //         five10kCount = data?.k_number_all;
    //         pmaCount = data?.pma_number_all;
    //         registrationCount = data?.reg_all;
    //         break;

    //     default:
    //         break;
    // }

    return (
        <div>
            <div className="d-flex flex-row-fluid px-1 mb-5">
                <div className="d-flex flex-column-auto align-items-center justify-content-center">
                    <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center ms-4">
                            <span>Recent US Regulatory Activity</span>
                        </div>
                    </h1>
                </div>
            </div>
            <div className='row g-5 g-xl-10 mb-10'>
                <div className='col-12'>
                    <div className="d-flex justify-content-center">
                        <div style={{ width: '100%' }}>
                            {/* First row */}
                            <div className='row g-5 mb-5'>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Guidance Documents</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetGuidances
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Guidance'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                            columns={{ count: 1 }}
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Final Rules</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetRegulatory
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Rule'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Registrations</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetRegistration
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Registration'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                            columns={{ count: 1 }}
                                            showChart={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Second row */}
                            <div className='row g-5'>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Warning Letters</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetWarningComponent
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Warning Letters'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                            columns={{ count: 1 }}
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Notices</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetRegulatory
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Notice'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-4'>
                                    <div className="card">
                                        <div className="card-header py-2 d-flex justify-content-center" style={{ minHeight: 'auto', borderBottom: 'none' }}>
                                            <h3 className='card-title m-1'>
                                                <span className='card-label fw-bold fs-4'>Recalls</span>
                                            </h3>
                                        </div>
                                        <MixedWidgetRecalls
                                            className='card-xxl-stretch'
                                            chartHeight='240px'
                                            days={days}
                                            doctype='Recalls'
                                            title=''
                                            activity='all'
                                            scale='logarithmic'
                                            columns={{ count: 1 }}
                                            showChart={true}
                                            summaryPage={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Commenting out table content
            {Tablecontent}
            */}
        </div>
    )
}
export { CountAndTable }
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

const AdverseEventsDetails = () => {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [tab, setTab] = useState('Patients');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const searchTerm = location.state ? location.state.searchTerm : '';
    const currentPage = location.state ? location.state.currentPage : 1;
    // const replacements = {
    //     '': '™',
    //     '': '"',
    //     '': '"',
    //     '®': '®',
    //     '©': '©',
    //     '': '—',
    //     '': '–',
    //     '': '•',
    //     '': '\'',
    //     '': '\'',
    //     '': '…',
    //     '': '¨',
    //     '': '‰',
    //     '': '‹',
    //     '': '›',
    //     '': '«',
    //     '': '»',
    //     '¿': '®',
    // }
    const NavItem = ({ label, activeTab, setTab }) => {
        return (
            <li className='nav-item fs-4'>
                <h5
                    className={clsx('nav-link cursor-pointer fw-semibold', {
                        'active text-dark fw-bold': activeTab === label,
                    })}
                    onClick={() => setTab(label)}
                    role='tab'
                >
                    {label}
                </h5>
            </li>
        );
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const formattedString = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        return new Date(formattedString).toLocaleDateString('en-US', { timeZone: 'UTC' });
    };
    
    const Details = ({ label, value }) => {
        let displayValue = 'N/A';
        if (Array.isArray(value)) {
            displayValue = value.length === 0 || (value.length === 1 && value[0] === "") ? 'N/A' : value.join(', ');
        } else if (value) {
            displayValue = value;
        }
    
        return (
            <div className="mb-3 row">
                <div className="col-2">
                    <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                        {label}
                    </span>
                </div>
                <div className="col-10">
                    <span className="card-title fw-bold text-gray-600 fs-5">
                        {displayValue}
                    </span>
                </div>
            </div>
        );
    };
    
    
    useEffect(() => {
        const fetchDevice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/fda/elastic/details/adverse-events/${id}`);
                setDetails(response.data);
                console.log(response.data);

                setIsLoading(false);
            } catch (error) {
                console.log('Error fetching document:', error);
                setIsLoading(false);
            }
        };
        fetchDevice();
    }, [id]);

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={"/device-intelligence/adverseevents"}
                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Adverse Events List
                    </Link>
                </div>
            </div>

            <div className='card mt-4 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>Medical Device Report #{id}</h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Adverse Event Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <NavItem label="Patients" activeTab={tab} setTab={setTab} />
                        <NavItem label="Devices" activeTab={tab} setTab={setTab} />
                        <NavItem label="MDR" activeTab={tab} setTab={setTab} />

                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <HourGlass height="49vh" />
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Patients' })}>
                                
                                <div className="card-body card-scroll">

            

                                    {details?.patient?.length > 0 && (
                                        details.patient.map((patient, index) => (
                                            
                                            <div key={index}>
                                                
                                                <Details label="Date Received" value={formatDate(patient.date_received)} />

                                                <Details label="Patient Age" value={patient.patient_age} />

                                                <Details label="Patient Ethnicity" value={patient.patient_ethnicity} />

                                                <Details label="Patient Race" value={patient.patient_race} />

                                                <Details label="Patient Sex" value={patient.patient_sex} />

                                                <Details label="Patient Weight" value={patient.patient_weight} />

                                                <Details label="Patient Sequence Number" value={patient.patient_sequence_number} />

                                                <Details label="Patient Problems" value={patient.patient_problems} />

                                                <Details label="Sequence Number Outcome" value={patient.sequence_number_outcome} />

                                                <Details label="Sequence Number Treatment" value={patient.sequence_number_treatment} />

                                                {index < details.patient.length - 1 &&<div className='separator separator-dashed border-gray-600 my-4'></div>}
                                                
                                            </div>
                                            
                                        ))
                                    )}
                                </div>
                            </div>

                            <div className='tab-content'>
                                <div className={clsx('tab-pane', { active: tab === 'Devices' })}>
                                    
                                    <div className="card-body card-scroll">

                                        {details?.device?.length > 0 && (
                                            details.device.map((device, index) => (
                                            <div>
                                                <Details label="Brand Name" value={device.brand_name} />
                                                <Details label="Catalog Number" value={device.catalog_number} />
                                                <Details label="Combination Product Flag" value={device.combination_product_flag} />
                                                <Details label="Date Received" value={device.date_received} />
                                                <Details label="Date Removed Flag" value={device.date_removed_flag} />
                                                <Details label="Device Age Text" value={device.device_age_text} />
                                                <Details label="Device Availability" value={device.device_availability} />
                                                <Details label="Device Evaluated by Manufacturer" value={device.device_evaluated_by_manufacturer} />
                                                <Details label="Device Event Key" value={device.device_event_key} />
                                                <Details label="Device Operator" value={device.device_operator} />
                                                <Details label="Device Report Product Code" value={device.device_report_product_code} />
                                                <Details label="Device Sequence Number" value={device.device_sequence_number} />
                                                <Details label="Generic Name" value={device.generic_name} />
                                                <Details label="Implant Flag" value={device.implant_flag} />
                                                <Details label="Lot Number" value={device.lot_number} />
                                                <Details label="Manufacturer Address 1" value={device.manufacturer_d_address_1} />
                                                <Details label="Manufacturer Address 2" value={device.manufacturer_d_address_2} />
                                                <Details label="Manufacturer City" value={device.manufacturer_d_city} />
                                                <Details label="Manufacturer Country" value={device.manufacturer_d_country} />
                                                <Details label="Manufacturer Name" value={device.manufacturer_d_name} />
                                                <Details label="Manufacturer Postal Code" value={device.manufacturer_d_postal_code} />
                                                <Details label="Manufacturer State" value={device.manufacturer_d_state} />
                                                <Details label="Manufacturer ZIP Code" value={device.manufacturer_d_zip_code} />
                                                <Details label="Manufacturer ZIP Code Ext." value={device.manufacturer_d_zip_code_ext} />
                                                <Details label="Model Number" value={device.model_number} />
                                                <Details label="Other ID Number" value={device.other_id_number} />
                                                <Details label="UDI DI" value={device.udi_di} />
                                                <Details label="UDI Public" value={device.udi_public} />

                                                {/* Nested openfda object */}
                                                <Details label="Device Class" value={device.openfda.device_class} />
                                                <Details label="Device Name" value={device.openfda.device_name} />
                                                <Details label="Medical Specialty Description" value={device.openfda.medical_specialty_description} />
                                                <Details label="Regulation Number" value={device.openfda.regulation_number} />

                                                {index < details.device.length - 1 &&<div className='separator separator-dashed border-gray-600 my-4'></div>}

                                            </div>
                                            
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='tab-content'>
                                <div className={clsx('tab-pane', { active: tab === 'MDR' })}>
                                    
                                    <div className="card-body card-scroll">

                                        {details?.mdr_text?.length > 0 && (
                                            details.mdr_text.map((device, index) => (
                                            <div>
                                                <Details label="MDR Text Key" value={device.mdr_text_key} />
                                                <Details label="Patient Sequence Number" value={device.patient_sequence_number} />
                                                <Details label="Text" value={device.text} />
                                                <Details label="Text Type Code" value={device.text_type_code} />

                                                {index < details.mdr_text.length - 1 &&<div className='separator separator-dashed border-gray-600 my-4'></div>}

                                            </div>
                                            ))
                                        )}


                                    </div>
                                </div>
                            </div>
                                    {/* {details && details.openfda_device_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Device Name
                                                    </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.openfda_device_name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.brand_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Brand Name
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">  
                                                    {Object.keys(replacements).reduce((str, key) => {
                                                        return str.replace(new RegExp(key, 'g'), replacements[key]);
                                                    }, details.brand_name)}
                                                </span>
                                            </div>
                                        </div>
                                    )} */}
                                    {/* {device && device.manufacturer_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Manufacturer Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.manufacturer_name}</span>
                                            </div>
                                        </div>
                                    )} */}
                                    {/* {details && details.device_operator && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Device Operator
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.device_operator}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.device_report_product_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Product Code
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <Link to={`/device-intelligence/classification/${details.device_report_product_code}`} target="_blank" rel="noopener noreferrer" className="fw-bold fs-5" style={{ color: 'primary' }}>{details.device_report_product_code}</Link>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.openfda_device_class && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Device Class
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.openfda_device_class}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.openfda_medical_specialty_description
                                        && (
                                            <div className="mb-3 row">
                                                <div className="col-2">
                                                    <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                        Specialty
                                                    </span>
                                                </div>
                                                <div className="col-10">
                                                    <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                        {details.openfda_medical_specialty_description}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    {details && details.openfda_regulation_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Regulation Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.openfda_regulation_number}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.date_received && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Received Date
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">
                                                    {new Date(details.date_received).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.expiration_date_of_device && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Expiration Date
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">
                                                    {new Date(details.expiration_date_of_device).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.patient_problems && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Patient Problems
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.patient_problems}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.additional_info_contact && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Additional Info Contact
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details.additional_info_contact}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.event_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Event Type
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details?.event_type}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.event_report_source && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Event Report Source
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details?.event_report_source}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.event_source_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Event Source Type
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details?.event_source_type}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {details && details.reported_event_by && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Event Reported By
                                                </span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {details?.reported_event_by}
                                                </span>
                                            </div>
                                        </div>
                                    )} */}
                                
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default AdverseEventsDetails;
